import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import Cal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form-tabs';
import ListEmpty from '../../components/List/empty';
import InputClient from '../../components/Form/client';
import InputText from '../../components/Form/input-text';
import InputDate from '../../components/Form/input-date';
import InputDatetime from '../../components/Form/datetime';
import InputRadio from '../../components/Form/radio';
import InputToggle from '../../components/Form/toggle';
import Select from '../../components/Form/select';
import InputDuration from '../../components/Form/duration';
import DataInfo from '../../components/Form/info';
import Loader from '../../components/Form/loader';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import ModalClients from '../../components/Modals/clients';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewRecSessionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      editing: false,
      clients: global.clients,
      sessions: global.sessions,
      client: null,
      selected: false,
      dur: 30,
      preview: true,
      hrs: [],
      min: [],
      date: Moment().startOf('week').format('DD/MM/YYYY HH:mm'),
      hour: '00',
      mins: '00',
      custom: [],
      cdur: 75,
      error: [false, false, false],
      interval: 7,
      weekday: 0,
      start: 0,
      loading: false,
      maxNum: 0,
      showMore: false,
      trainerId: global.uidUser,
      trainerName: global.userName,
      location: global.loc,
      link: '',
      name: '',
      desc: '',
      showEnd: false,
      dateEnd: Moment().add(1, 'months').format('DD/MM/YYYY'),
      staff: global.userStaff,
      trainers: [],
      locations: [],
      showModalClients: false,
      showModalDelete: false,
      showModalUpdate: false,
      showModalRestart: false,
      startday: 0,
      preselected: false,
      restart: 1
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Recurring 1:1 Session - PT Mate')
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('recurringLoaded', (event) => this.configureData())

    Moment.updateLocale("en", { week: {
      dow: 1, // First day of week is Monday
    }});
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false
    })
  }


  configureData() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      var tmp22 = []
      var tmp23 = []
      for(var item1 of global.clients) {
        tmp1.push(item1)
      }

      for(var item2 of global.userStaff) {
        if((item2.data.role === 'assistant' || item2.data.role === 'assistant2' || item2.data.role === 'trainer') && item2.data.status === 'active') {
          tmp2.push(item2)
        }
      }
      if(tmp2.length > 0) {
        tmp22 = [{value: '', name: '- '+lang.t('calendar:form.notassigned')+' -'}, {value: global.uidUser, name: lang.t('common:label.yourself')}]
        if(global.userRole === 'manager') {
          tmp22.push({value: global.uid, name: global.spaceOwner})
        }
        for(var t of tmp2) {
          tmp22.push({value: t.id, name: t.data.name})
        }
      }
      if(global.locations.length > 0) {
        tmp23 = [{value: '', name: '- '+lang.t('calendar:form.notassigned')+' -'}]
        for(var l of global.locations) {
          tmp23.push({value: l.id, name: l.data.name})
        }
      }
      if(global.linkCli !== '') {
        var arr = global.linkCli.split('/')
        for(var cl of global.clients) {
          if(cl.id === arr[arr.length-2]) {
            this.setState({
              preselected: true,
              client: cl
            })
          }
        }
      }
      this.setState({
        clients: tmp1,
        staff: tmp2,
        trainers: tmp22,
        locations: tmp23,
        sessions: global.sessions,
      })
      if(this.props.location.pathname.indexOf('recurring-templates/sessions/') !== -1) {
        this.configureTemplate()
      }
    }
  }


  configureTemplate() {
    if(!this.state.loading) {
      var arr = this.props.location.pathname.split('/')
      var id = arr[arr.length-1]
      var item = null
      var cl = null
      var dur = 30
      var day = ''
      var hrs = ''
      var min = ''
      var preview = true
      var weekday = 0
      var interval = 7
      var tid = global.uidUser
      var tname = global.userName
      var link = ''
      var name = ''
      var desc = ''
      var end = Moment().add(1, 'months').format('DD/MM/YYYY')
      var showEnd = false
      var location = ''

      for(var session of global.recurring) {
        if(session.id === id) {
          item = session
          dur = session.data.duration
          interval = session.data.interval
          day = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
          hrs = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('HH')
          min = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('mm')
          weekday = parseInt(Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('d'))
          if(!session.data.preview && session.data.preview !== undefined) {
            preview = false
          }
          for(var client1 of global.clients) {
            if(client1.id === session.data.client) {
              cl = client1
            }
          }
          for(var client2 of global.clientsInactive) {
            if(client2.id === session.data.client) {
              cl = client2
            }
          }
          if(session.data.trainerId !== undefined) {
            tid = session.data.trainerId
            tname = session.data.trainerName
          }
          if(session.data.link !== undefined) {
            link = session.data.link
          }
          if(session.data.desc !== undefined) {
            desc = session.data.desc
          }
          if(session.data.location !== undefined) {
            location = session.data.location
          }
          if(session.data.name !== undefined) {
            name = session.data.name
          }
          if(session.data.dateEnd !== undefined) {
            if(session.data.dateEnd !== 32503640400) {
              end = Moment(session.data.dateEnd, 'X').format('DD/MM/YYYY')
              showEnd = true
            }
          }
        }
      }

      var cdur = 75
      if(dur !== 30 && dur !== 40 && dur !== 45 && dur !== 60 && dur !== 90) {
        cdur = dur
        dur = 99
      }

      this.setState({
        client: cl,
        item: item,
        id: id,
        dur: dur,
        cdur: cdur,
        date: Moment(day, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm'),
        hour: hrs,
        mins: min,
        preview: preview,
        weekday: weekday-1,
        startday: weekday-1,
        interval: interval,
        trainerId: tid,
        trainerName: tname,
        location: location,
        link: link,
        name: name,
        desc: desc,
        showEnd: showEnd,
        dateEnd: end,
        editing: true,
      })
    }
  }



  // Weekdays stuff ------------------------------------------------------------



  setWeekday(event) {
    var date = Moment().startOf('week').add(event, 'days').add(this.state.start, 'days').format('DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins
    if(this.state.editing) {
      var diff = event-this.state.startday
      date = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(diff, 'days').format('DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins
    }
    this.setState({
      weekday: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    })
  }


  setStart(event) {
    var date = Moment().startOf('week').add(this.state.weekday, 'days').add(event, 'days').format('DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins
    this.setState({
      start: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    })
  }


  setHour(event) {
    var date = Moment().startOf('week').add(this.state.weekday, 'days').add(this.state.start, 'days').format('DD/MM/YYYY')+' '+event+':'+this.state.mins
    if(this.state.editing) {
      date = Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')+' '+event+':'+this.state.mins
    }
    this.setState({
      hour: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    });
  }


  setMins(event) {
    var date = Moment().startOf('week').add(this.state.weekday, 'days').add(this.state.start, 'days').format('DD/MM/YYYY')+' '+this.state.hour+':'+event
    if(this.state.editing) {
      date = Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')+' '+this.state.hour+':'+event
    }
    this.setState({
      mins: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    });
  }


  setTrainer(item) {
    var name = ''
    for(var trainer of global.userStaff) {
      if(trainer.id === item) {
        name = trainer.data.name
      }
    }
    if(item === global.uidUser) {
      name = global.userName
    }
    this.setState({
      trainerId: item,
      trainerName: name
    })
  }


  checkCreateTemplate() {
    var passed = true
    var tmp = [false, false, false]
    this.setState({error: tmp})
    if(this.state.client === null) {
      tmp[0] = true
      passed = false
    }
    this.setState({
      error: tmp,
      loading: passed
    }, () => {
      if(passed) {
        this.configureCreateTemplate()
      }
    })
    
  }


  configureCreateTemplate() {
    var duration = parseInt(this.state.dur)
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }
    var plan = false
    for(var sub of this.state.client.subscriptions) {
      if(!sub.data.group || sub.data.is11) {
        plan = true
      }
    }
    if(global.userStripeBank == null) {
      plan = true
    }

    global.updatingRec = true

    var obj = Firebase.database().ref('/recurring/'+global.uid).push()
    this.prepareSessions(0, plan, duration, obj.key)
  }


  prepareSessions(num, plan, duration, id) {
    var count = 0
    var end = parseInt(Moment('01/01/3000', 'DD/MM/YYYY').format('X')) //32503640400
    if(this.state.showEnd) {
      end = parseInt(Moment(this.state.dateEnd, 'DD/MM/YYYY').format('X'))
    }
    if(end < this.state.date) {
      end = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((this.state.interval), 'days').format('X'))
    }
    var endDate = parseInt(Moment().add(90, 'days').format('X'))
    if(endDate > end) {
      endDate = end
    }
    for(var i=0; i<18; i++) { 
      var date = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((i*this.state.interval), 'days').format('X'))
      if(date < endDate && date > parseInt(Moment().format('X'))) {
        count++
      }
    }
    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }

    this.createTemplate(count, end, duration, id, location, locationName)

    setTimeout(() => {
      for(var j=0; j<18; j++) { 
        var date1 = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((j*this.state.interval), 'days').format('X'))
        if(date1 < endDate && date1 > parseInt(Moment().format('X'))) {
          this.createSession(j, plan, duration, id, location, locationName)
        }
      }
    }, 1000);

    this.setState({
      maxNum: count
    })
  }


  createTemplate(num, end, duration, id, location, locationName) {
    global.badgeLocked = true
    Firebase.database().ref('/recurring/'+global.uid+'/'+id).set({
      client: this.state.client.id,
      group: false,
      duration: duration,
      preview: this.state.preview,
      date: this.state.date,
      timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('X')),
      dateEnd: end,
      interval: this.state.interval,
      current: num,
      uid: global.uid,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
      name: this.state.name,
      desc: this.state.desc,
    }).then(() => {
      //this.createSessions(0, plan, duration, obj.key)
      //global.message = 'Template successfully created'
      //this.props.history.push(ROUTES.RECURRING)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
  }


  createSession(num, plan, duration, id, location, locationName) {
    var obj = Firebase.database().ref('/sessions/'+global.uid).push()
    obj.update({
      client: this.state.client.id,
      group: false,
      duration: duration,
      preview: this.state.preview,
      date: Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days').format('DD/MM/YYYY HH:mm'),
      timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days').format('X')),
      notes: '',
      plan: plan,
      attendance: 2,
      uid: global.uid,
      template: id,
      templateSeq: num,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
      name: this.state.name,
      desc: this.state.desc,
    }).then(() => {
      this.checkFinished(num)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
    // Scheduled notifications
    if(this.state.client.data.pushToken !== undefined && this.state.client.data.pushToken !== '') {
      var date = Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('X')
      var scheduled = Moment(date, 'X').add(-global.userSessionReminder, 'hours').format('X')
      Firebase.database().ref('/schedule/'+obj.key+'-push').update({
        title: lang.t('messaging:push.sessionreminder.title'),
        desc: lang.t('messaging:push.bookedin.text4')+(this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name)+' '+Cal.getSpecialDatePush(date, scheduled)+".",
        type: "",
        timestamp: parseInt(Moment(date, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [this.state.client.data.pushToken],
        message: "push",
        uid: global.uid,
        iid: obj.key
      })
    }
    if(this.state.client.data.email !== '' && global.spaceEmailReminder && (this.state.client.data.emailReminder === undefined || this.state.client.data.emailReminder)) {
      Firebase.database().ref('/schedule/'+obj.key+'-email').update({
        title: global.userBusiness,
        desc: (this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name),
        type: Cal.getSpecialDatePush(date, scheduled),
        timestamp: parseInt(Moment(date, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [this.state.client.data.email],
        message: "sessionemail",
        uid: global.uid,
        iid: global.uid
      })
    }
  }


  checkFinished(num) {
    if(num === this.state.maxNum-1) {
      global.message = lang.t('messaging:message.templatecreated')
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'recurring');
      }, 1000);
      this.props.history.push(ROUTES.RECURRING)
    }
  }



  // Update template ------------------------------------------------------------



  checkUpdateTemplate() {
    var passed = true
    var tmp = [false, false]
    this.setState({error: tmp})
    if(this.state.client === null) {
      tmp[0] = true
      passed = false
    }
    this.setState({error: tmp})
    if(passed) {
      this.setState({
        showModalUpdate: true
      })
    }
  }


  updateTemplate() {
    this.hideModals()
    var duration = parseInt(this.state.dur)
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }

    var plan = false
    if(this.state.client !== null) {
      for(var sub of this.state.client.subscriptions) {
        if(!sub.data.group) {
          plan = true
        }
      }
    }

    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }

    var end = parseInt(Moment('01/01/3000', 'DD/MM/YYYY').format('X')) //32503640400
    if(this.state.showEnd) {
      end = parseInt(Moment(this.state.dateEnd, 'DD/MM/YYYY').format('X'))
    }
    if(end < this.state.date) {
      end = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((this.state.interval), 'days').format('X'))
    }
    
    if(global.userStripeBank == null) {
      plan = true
    }

    Firebase.database().ref('/recurring/'+global.uid+'/'+this.state.item.id).update({
      duration: duration,
      preview: this.state.preview,
      date: this.state.date,
      dateEnd: end,
      timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('X')),
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
      name: this.state.name,
      desc: this.state.desc,
    }).then(() => {
      this.updateSessions(plan, duration, end, location, locationName)
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.templateupdated'))
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
  }


  checkRestartTemplate() {
    var passed = true
    var tmp = [false, false]
    this.setState({error: tmp})
    if(this.state.client === null) {
      tmp[0] = true
      passed = false
    }
    this.setState({error: tmp})
    if(passed) {
      this.setState({
        showModalRestart: true
      })
    }
  }


  updateRestart() {
    this.hideModals()
    this.setState({
      loading: true
    })
    var duration = parseInt(this.state.dur)
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }

    var plan = false
    if(this.state.client !== null) {
      for(var sub of this.state.client.subscriptions) {
        if(!sub.data.group) {
          plan = true
        }
      }
    }

    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }

    var dbase = Moment().startOf('week').add(this.state.weekday, 'days').format('DD/MM/YYYY')
    var dtime = Moment(dbase+' '+this.state.hour+':'+this.state.mins, 'DD/MM/YYYY HH:mm')
    var start = Moment(dtime).add(this.state.restart, 'weeks').format('DD/MM/YYYY HH:mm')

    var end = parseInt(Moment('01/01/3000', 'DD/MM/YYYY').format('X')) //32503640400
    if(this.state.showEnd) {
      end = parseInt(Moment(this.state.dateEnd, 'DD/MM/YYYY').format('X'))
    }
    if(end < this.state.date) {
      end = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((this.state.interval), 'days').format('X'))
    }
    
    if(global.userStripeBank == null) {
      plan = true
    }

    //Create new sessions
    var num = 1
    var endDate = parseInt(Moment().add(90, 'days').format('X'))
    if(endDate > end) {
      endDate = end
    }
    var obj = Firebase.database().ref('/recurring/'+global.uid).push()

    for(var j=num; j<(num+18); j++) {
      var nsdate = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((j*this.state.item.data.interval), 'days').format('X'))
      if(nsdate < endDate) {
        this.createAdditionalSessions(nsdate, num, plan, duration, location, locationName, obj.key)
        num++
      }
    }

    obj.update({
      client: this.state.item.data.client,
      group: false,
      duration: duration,
      preview: this.state.preview,
      date: start,
      timestamp: parseInt(Moment(start, 'DD/MM/YYYY HH:mm').format('X')),
      dateEnd: end,
      interval: this.state.interval,
      current: num,
      uid: global.uid,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
      name: this.state.name,
      desc: this.state.desc,
    }).then(() => {
      global.message = lang.t('messaging:message.templaterestarted')
      setTimeout(() => {
        Firebase.database().ref('/recurring/'+global.uid+'/'+this.state.item.id).remove()
        this.props.history.push(ROUTES.RECURRING)
      }, 3000);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
  }


  updateSessions(plan, duration, end, location, locationName) {
    var sess = []
    var del = []
    var date = parseInt(Moment().format('X'))
    var num = 0
    for(var session of this.state.sessions) {
      var sdate = parseInt(Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X'))
      if(session.data.template === this.state.item.id && sdate > date) {
        var nd = session.data.templateSeq*this.state.item.data.interval
        if(sdate > end) {
          del.push(session.id)
        } else {
          if(session.data.templateSeq > 0) {
            num = session.data.templateSeq
          }
          sess.push({
            id: session.id,
            date: Moment(this.state.date, 'DD/MM/YYYY HH:mm').add(nd, 'days').format( 'DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins,
            trainerId: this.state.trainerId,
            trainerName: this.state.trainerName,
            location: location,
            locationName: locationName,
          })
        }
      }
    }
    for(var item of sess) {
      var ts = parseInt(Moment(item.date, 'DD/MM/YYYY HH:mm').format('X'))
      if(item.timestamp !== undefined) {
        ts = parseInt(Moment(item.timestamp, 'X').format('X'))
      }
      Firebase.database().ref('/sessions/'+global.uid+'/'+item.id).update({
        duration: duration,
        preview: this.state.preview,
        date: item.date,
        timestamp: ts,
        plan: plan,
        trainerId: this.state.trainerId,
        trainerName: this.state.trainerName,
        location: location,
        locationName: locationName,
        link: this.state.link,
        name: this.state.name,
        desc: this.state.desc,
      })
      // Scheduled reminders
      var scheduled = Moment(ts, 'X').add(-global.userSessionReminder, 'hours').format('X')
      for(var sitem of global.schedule) {
        if(sitem.id === item.id+'-push') {
          Firebase.database().ref('/schedule/'+sitem.id).update({
            timestamp: parseInt(Moment(ts, 'X').add(-global.userSessionReminder, 'hours').format('X')),
            desc: lang.t('messaging:push.bookedin.text4')+(this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name)+' '+Cal.getSpecialDatePush(ts, scheduled)+".",
          })
        }
        if(sitem.id === item.id+'-email') {
          Firebase.database().ref('/schedule/'+sitem.id).update({
            timestamp: parseInt(Moment(ts, 'X').add(-global.userSessionReminder, 'hours').format('X')),
            desc: (this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name),
            type: Cal.getSpecialDatePush(ts, scheduled),
          })
        }
      }
    }
    for(var item2 of del) {
      Firebase.database().ref('/sessions/'+global.uid+'/'+item2).remove()
    }
    //Create additional sessions
    num++
    var endDate = parseInt(Moment().add(90, 'days').format('X'))
    if(endDate > end) {
      endDate = end
    }
    for(var j=num; j<(num+18); j++) {
      var nsdate = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((j*this.state.item.data.interval), 'days').format('X'))
      if(nsdate < endDate) {
        this.createAdditionalSessions(nsdate, num, plan, duration, location, locationName, this.state.item.id)
      }
    }
    
  }


  createAdditionalSessions(date, num, plan, duration, location, locationName, id) {
    var obj = Firebase.database().ref('/sessions/'+global.uid).push()
    obj.update({
      client: this.state.client.id,
      group: false,
      duration: duration,
      preview: this.state.preview,
      date: Moment(date, 'X').format('DD/MM/YYYY HH:mm'),
      timestamp: parseInt(date),
      notes: '',
      plan: plan,
      attendance: 2,
      uid: global.uid,
      template: id,
      templateSeq: num,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
      name: this.state.name,
      desc: this.state.desc
    })
    // Scheduled notifications
    if(this.state.client.data.pushToken !== undefined && this.state.client.data.pushToken !== '') {
      var date = Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('X')
      var scheduled = Moment(date, 'X').add(-global.userSessionReminder, 'hours').format('X')
      Firebase.database().ref('/schedule/'+obj.key+'-push').update({
        title: lang.t('messaging:push.sessionreminder.title'),
        desc: lang.t('messaging:push.bookedin.text4')+(this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name)+' '+Cal.getSpecialDatePush(date, scheduled)+".",
        type: "",
        timestamp: parseInt(Moment(date, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [this.state.client.data.pushToken],
        message: "push",
        uid: global.uid,
        iid: obj.key
      })
    }
    if(this.state.client.data.email !== '' && global.spaceEmailReminder && (this.state.client.data.emailReminder === undefined || this.state.client.data.emailReminder)) {
      Firebase.database().ref('/schedule/'+obj.key+'-email').update({
        title: global.userBusiness,
        desc: (this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name),
        type: Cal.getSpecialDatePush(date, scheduled),
        timestamp: parseInt(Moment(date, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [this.state.client.data.email],
        message: "sessionemail",
        uid: global.uid,
        iid: global.uid
      })
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalClients: false,
      showModalDelete: false,
      showModalUpdate: false,
      showModalRestart: false,
    })
  }


  modalClick(item) {
    this.hideModals()
    this.setState({
      client: item,
    })
  }


  deleteTemplate() {
    this.hideModals()
    this.setState({
      loading: true,
    }, () => {
      var ids = []
      var date = parseInt(Moment().format('X'))
      
      setTimeout(() => {
        for(var session of this.state.sessions) {
          var sdate = parseInt(Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X'))
          if(session.data.template === this.state.item.id && sdate > date) {
            ids.push(session.id)
          }
        }
        Firebase.database().ref('/recurring/'+global.uid+'/'+this.state.item.id).remove().then(() => {
          for(var item of ids) {
            Firebase.database().ref('/sessions/'+global.uid+'/'+item).remove()
            Firebase.database().ref('/schedule/'+item+"-push").remove()
            Firebase.database().ref('/schedule/'+item+"-email").remove()
          }
          global.message = lang.t('messaging:message.templatedeleted')
          this.props.history.push(ROUTES.RECURRING)
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
          this.setState({loading: false})
        })
      }, 1000);
    })
  }



  // Display stuff ------------------------------------------------------------



  renderHeaderLink() {
    var label = global.currentPage
    if(global.currentPage === '/clients' && global.currentClient !== '') {
      label = '/clients/'+global.currentClient+'/sessions'
    }
    return label
  }


  renderLocation() {
    if(global.locations.length > 0 && HelperBusiness.getRoleIncl('trainer,admin')) {
      return <Select label={lang.t('calendar:form.location')} value={this.state.location} values={this.state.locations} onChange={(event) => this.setState({location: event})}/>
    }
  }


  renderTrainer() {
    if(this.state.staff.length > 0 && HelperBusiness.getRoleIncl('')) {
      return <Select label={lang.t('calendar:form.trainer')} value={this.state.trainerId} values={this.state.trainers} onChange={(event) => this.setTrainer(event)}/>
    }
  }


  renderRestart() {
    var list = [{value: 1, name: lang.t('calendar:form.nextweek')}, {value: 2, name: lang.t('calendar:form.weekof')+' '+Moment().startOf('week').add(2, 'weeks').format('D MMM')}, {value: 3, name: lang.t('calendar:form.weekof')+' '+Moment().startOf('week').add(3, 'weeks').format('D MMM')}, {value: 4, name: lang.t('calendar:form.weekof')+' '+Moment().startOf('week').add(4, 'weeks').format('D MMM')}, {value: 5, name: lang.t('calendar:form.weekof')+' '+Moment().startOf('week').add(5, 'weeks').format('D MMM')}, {value: 6, name: lang.t('calendar:form.weekof')+' '+Moment().startOf('week').add(6, 'weeks').format('D MMM')}]
    if(this.state.item !== null) {
      if(this.state.id !== '' && this.state.item.data.dateEnd !== undefined) {
        if(Moment(this.state.item.data.dateEnd, 'X') < Moment()) {
          return (
            <div>
              <div className="infobox small yellow mb-20">
                <p className="small">{lang.t('calendar:form.seriesofsessionsended')} {Moment(this.state.item.data.dateEnd, 'X').locale(lang.language).format('LL')}.</p>
              </div>
              <Select label={lang.t('calendar:form.restartseries')} value={this.state.restart} values={list} onChange={(event) => this.setState({restart: event})}/>
            </div>
          )
        }
      }
    }
  }


  renderEnd() {
    if(this.state.showEnd) {
      return <InputDate label="" value={Moment(this.state.dateEnd, 'DD/MM/YYYY').format('YYYY-MM-DD')} format='YYYY-MM-DD' min={Moment().format('YYYY-MM-DD')} max={Moment().add(2, 'months').format('YYYY-MM-DD')} onChange={(event) => this.setState({dateEnd: Moment(event).format('DD/MM/YYYY')})}/>
    }
  }


  renderMoreOptions() {
    if(this.state.showMore) {
      return (
        <div className="clear">
          <div className="sv-10"></div>
          <button className="btn tertiary small width-12 sub" onClick={() => this.setState({showMore: false})}>{lang.t('calendar:button.hidemoreoptions')}</button>
          <div className="sv-20"></div>
          <InputText label={lang.t('calendar:form.sessionname2')} value={this.state.name} onChange={(event) => this.setState({name: event})}/>
          <InputText label={lang.t('calendar:form.sessiondescription')} value={this.state.desc} onChange={(event) => this.setState({desc: event})}/>
          <InputToggle label={lang.t('calendar:form.preview.label')} value={this.state.preview} onChange={() => this.setState({preview: !this.state.preview})} text={lang.t('calendar:form.preview.text')}/>
          <InputToggle label={lang.t('calendar:form.enddate.label')} value={this.state.showEnd} onChange={() => this.setState({showEnd: !this.state.showEnd})} text={lang.t('calendar:form.enddate.text')}/>
          <div className="clear"></div>
          {this.renderEnd()}
        </div>
      )
    } else {
      return (
        <div className="clear">
          <div className="sv-10"></div>
          <button className="btn tertiary small width-12 add" onClick={() => this.setState({showMore: true})}>{lang.t('calendar:button.showmoreoptions')}</button>
        </div>
        
      )
    }
  }


  renderButtons() {
    if(this.state.editing) {
      var expired = false
      if(this.state.item !== null) {
        if(Moment(this.state.item.data.dateEnd, 'X') < Moment() && this.state.item.data.dateEnd !== undefined) {
          expired = true
        }
      }
      if(expired) {
        return (
          <div>
            <button className="btn primary mb-20" onClick={() => this.checkRestartTemplate()}>{lang.t('calendar:button.restartseries')}</button>
            <button className="btn tertiary close width-12" onClick={() => this.setState({showModalDelete: true})}>{lang.t('calendar:button.deletetemplate')}</button>
          </div>
        )
      } else {
        return (
          <div>
            <button className="btn primary mb-20" onClick={() => this.checkUpdateTemplate()}>{lang.t('calendar:button.updatetemplate')}</button>
            <button className="btn tertiary close width-12" onClick={() => this.setState({showModalDelete: true})}>{lang.t('calendar:button.deletetemplate')}</button>
          </div>
        )
      }
    } else {
      return <button className="btn primary" onClick={() => this.checkCreateTemplate()}>{lang.t('calendar:button.createtemplate')}</button>
    }
  }


  renderRepeats() {
    if(this.state.editing && this.state.item !== null) {
      return <DataInfo label={lang.t('calendar:form.repeats')} value={this.state.item.data.interval === 7 ? lang.t('calendar:label.week') : lang.t('calendar:label.fortnight')}/>
    } else {
      return (
        <InputRadio
          label={lang.t('calendar:form.repeats')}
          value={this.state.interval}
          clickElement={(event) => this.setState({interval: event})}
          values={[{name: lang.t('calendar:label.week'), value: 7}, {name: lang.t('calendar:label.fortnight'), value: 14}]}
        />
      )
    }
  }


  renderTabs() {
    if(!this.state.editing && !this.state.preselected) {
      return (
        <div className="radios tabs">
          <button className="radio col-6 active">{lang.t('calendar:sessions.11')}</button>
          <button className="radio col-6" onClick={() => this.props.history.push(ROUTES.NEWRECAVAILABILITY)}>{lang.t('calendar:form.11availability')}</button>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className={(this.state.editing || this.state.preselected) ? 'content form-sidebar' : 'content form-sidebar withtabs'}>
            <div className="col-6 mt-20">
              <div className={(this.state.editing || this.state.preselected) ? 'box' : 'box withtabs'}>
                {this.renderTabs()}
                <div className="inner">
                  <div className="col-12 clear">
                    <InputClient client={this.state.client} error={this.state.error[0]} errormsg={lang.t('calendar:error.client')} id='recurring' presel={(this.state.editing || this.state.preselected)} clickElement={() => this.setState({showModalClients: true})}/>
                    <div className="sv-20 clear"></div>
                    {this.renderRepeats()}
                  </div>
                  <InputDatetime type={this.state.editing ? 'recurringedit' : 'recurring'} start={this.state.start} weekday={this.state.weekday} hour={this.state.hour} min={this.state.mins} setDay={(event) => this.setWeekday(event)} setHour={(event) => this.setHour(event)} setMin={(event) => this.setMins(event)} setStart={(event) => this.setStart(event)}/>
                  <div className="col-12 clear">
                    <InputDuration duration={this.state.dur} custom={this.state.cdur} setDuration={(event) => this.setState({dur: event})} setCustom={(event) => this.setState({cdur: event})}/>
                    <InputText label={lang.t('calendar:form.virtual')} placeholder='Zoom, Hangouts, Skype...' value={this.state.link} onChange={(event) => this.setState({link: event})}/>
                    {this.renderTrainer()}
                    {this.renderLocation()}
                    {this.renderRestart()}
                    {this.renderMoreOptions()}
                  </div>
                  <div className="clear sv-30"></div>
                  {this.renderButtons()}
                </div>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id={this.state.editing ? 'rec11edit' : 'rec11new'}/>
              </div>
            </div>
            <div className="clear sv-40"></div>
  
          </div>
          <div className="btn-news" onClick={() => window.Intercom('startTour', '318173')}>Show me around</div>
          <Header title={this.state.editing ? lang.t('calendar:title.recurringtemplate') : lang.t('calendar:title.newrecurringtemplate')} link={this.renderHeaderLink()} type={(this.state.editing || this.state.preselected) ? 'simple' : 'recurring'} active='11'/>
          <Navigation active='calendar' />
          <ModalClients show={this.state.showModalClients} onHide={() => this.hideModals()} clickElement={(event) => this.modalClick(event)}/>
          <ModalMessageSimple type='deleterec' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteTemplate()}/>
          <ModalMessageSimple type='updaterec' show={this.state.showModalUpdate} onHide={() => this.hideModals()} clickMainButton={() => this.updateTemplate()}/>
          <ModalMessageSimple type={this.state.showEnd ? 'restartrec3' : 'restartrec4'} show={this.state.showModalRestart} var={Moment(this.state.dateEnd, 'DD/MM/YYYY').locale(lang.language).format('D MMM YYYY')} onHide={() => this.hideModals()} clickMainButton={() => this.updateRestart()}/>
          <Loader show={this.state.loading} label={this.state.editing ? lang.t('messaging:loader.recurring1') : lang.t('messaging:loader.recurring2')}/>
        </div> 
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='recurring-locked' type='simple'/>
          </div>
          <Header title={lang.t('calendar:title.recurringtemplate')} link={'/calendar'} type='simple'/>
          <Navigation active='calendar' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common','messaging'])(withRouter(withAuthorization(condition)(NewRecSessionPage)));