import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Firebase from 'firebase';
import EventEmitter from '../../helper/Emitter';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ModalPayment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      payment: props.payment,
      show: props.show,
      hidden: 'hidden',
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      payment: props.payment,
      show: props.show,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  refundCash() {
    Firebase.database().ref('/payments/'+global.uidUser+'/'+this.state.payment.id).update({
      refund: Moment().format('X')
    }).then(() => {
      this.hide()
      EventEmitter.dispatch('showMessage', lang.t('modals:payment.message'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    Firebase.database().ref('/activity/'+global.uidUser).push({
      type: 'cashrefunded',
      data: this.state.payment.data.client+','+this.state.payment.data.amount,
      date: Moment().format('DD/MM/YYYY HH:mm')
    })
  }


  refundPayment() {
    this.hide()
    this.props.onRefund()
  }


  getClientName(id) {
    var name = lang.t('common:label.deletedclient')
    for(var client of global.clients) {
      if(client.id === id) {
        name = client.data.name
      }
    }
    for(var inact of global.clientsInactive) {
      if(inact.id === id) {
        name = client.data.name
      }
    }
    return name
  }


  renderPaymentName(item) {
    var label = item.data.name
    if(item.data.subscription !== undefined && item.data.subscription !== '' && this.state.item !== null) {
      for(var sub of this.state.products) {
        if(sub.id === item.data.subscription) {
          label = sub.data.name
        }
      }
    }
    if(item.data.name.indexOf('Invoice ') !== -1 || item.data.name.indexOf('Subscription ') !== -1) {
      label = lang.t('modals:payment.membership')
    }
    return label
  }


  renderPaymentDate(item) {
    var label = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')
    if(item.data.timestamp !== undefined) {
      label = Moment(item.data.timestamp, 'X').locale(lang.language).format('D MMM YYYY')
    }
    return label
  }


  renderNet() {
    if(this.state.payment.data.fee !== undefined) {
      if(this.state.payment.data.fee === 0) {
        return (
          <div className="lft">{global.curSym}{(this.state.payment.data.amount/100).toFixed(2)}</div>
        )
      } else {
        return (
          <div className="lft">{global.curSym}{((this.state.payment.data.amount/100)*0.9775-0.3).toFixed(2)}</div>
        )
      }
    } else {
      return (
        <div className="lft">{global.curSym}{((this.state.payment.data.amount/100)*0.9775-0.3).toFixed(2)}</div>
      )
    }
  }


  renderFees() {
    if(this.state.payment.data.fee !== undefined) {
      if(this.state.payment.data.fee === 0) {
        return (
          <div className="lft">-</div>
        )
      } else {
        return (
          <div className="lft">{global.curSym}{((this.state.payment.data.amount/100)*0.0225+0.3).toFixed(2)} ({global.stripeFee})</div>
        )
      }
    } else {
      return (
        <div className="lft">{global.curSym}{((this.state.payment.data.amount/100)*0.0225+0.3).toFixed(2)} ({global.stripeFee})</div>
      )
    }
  }


  renderNotes() {
    if(this.state.payment.data.notes !== '' && this.state.payment.data.notes !== undefined) {
      return (
        <div className="clear">
          <div className="label lft clear">{lang.t('modals:payment.notes')}</div>
          <div className="lft">{this.state.payment.data.notes}</div>
        </div>
      )
    }
  }


  renderMethod() {
    if(this.state.payment.data.fee !== undefined) {
      if(this.state.payment.data.fee === 0) {
        return (
          <div className="clear">
            <div className="label lft clear">{lang.t('modals:payment.method')}</div>
            <div className="lft">{lang.t('modals:payment.otherpaymentmethod')}</div>
          </div>
        )
      } else {
        return (
          <div className="clear">
            <div className="label lft clear">{lang.t('modals:payment.card')}</div>
            <div className="lft">{this.state.payment.data.type} {lang.t('modals:payment.ending')} {this.state.payment.data.last4}</div>
          </div>
        )
      }
    } else {
      return (
        <div className="clear">
          <div className="label lft clear">{lang.t('modals:payment.card')}</div>
          <div className="lft">{this.state.payment.data.type} {lang.t('modals:payment.ending')} {this.state.payment.data.last4}</div>
        </div>
      )
    }
  }


  renderButtonRefund() {
    if(this.state.payment.data.refund === undefined) {
      if(this.state.payment.data.fee === 0) {
        return (
          <div className="mt-20">
            <button className="btn tertiary width-12" onClick={() => this.refundCash()}>{lang.t('modals:payment.button')}</button>
          </div>
        )
      } else {
        return (
          <div className="mt-20">
            <button className="btn tertiary width-12" onClick={() => this.refundPayment()}>{lang.t('modals:simple.data65.button')}</button>
          </div>
        )
      }
    }
  }


  renderButton() {
    if(global.showHabits) {
      return <a className="btn primary" href={'https://ptmate.app/billing/receipt/'+this.state.payment.id} target="_blank" rel="noopener noreferrer">{lang.t('modals:payment.button.viewreceipt')}</a>
    } else {
      if(this.state.payment.data.fee === undefined || this.state.payment.data.fee !== 0) {
        return <a className="btn primary" href={this.state.payment.data.receipt} target="_blank" rel="noopener noreferrer">{lang.t('modals:payment.button.viewreceipt')}</a>
      } else {
        return <button className="btn tertiary width-12" onClick={() => this.refundCash()}>{lang.t('modals:payment.button')}</button>
      }
    }
  }


  renderGST() {
    if(global.spaceGST !== 0) {
      var label = '-'
      var mul = (this.state.payment.data.amount/100)/11
      var num = mul/10*global.spaceGST
      label = global.curSym+num.toFixed(2)
      return (
        <div>
          <div className="label lft clear">{lang.t('modals:payment.gstincl')}</div>
          <div className="lft">{label}</div>
        </div>
      )
    }
  }


  renderRefunded() {
    if(this.state.payment.data.refund !== undefined) {
      return (
        <div className="infobox yellow small mt-20"><p className="small">{lang.t('billing:label.refunded')} {Moment(this.state.payment.data.refund, 'X').locale(lang.language).format('llll')}</p></div>
      )
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{lang.t('modals:payment.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            <div className="info clear">
              <p className="label">{this.renderPaymentName(this.state.payment)}</p>
              <p className="big">{global.curSym}{(this.state.payment.data.amount/100).toFixed(2)}</p>
            </div>
            <div className="table">
              {this.renderGST()}
              <div className="label lft clear">{lang.t('modals:payment.stripefees')}</div>
              {this.renderFees()}
              <div className="label lft clear">{lang.t('modals:payment.afterfees')}</div>
              {this.renderNet()}
              <div className="clear"></div>
            </div>
            <div className="table notop">
              <div className="label lft">{lang.t('modals:payment.client')}</div>
              <div className="lft">{this.getClientName(this.state.payment.data.client)}</div>
              <div className="label lft clear">{lang.t('modals:payment.date')}</div>
              <div className="lft">{this.renderPaymentDate(this.state.payment)}</div>
              {this.renderMethod()}
              {this.renderNotes()}
              <div className="clear"></div>
            </div>
            {this.renderRefunded()}
            <div className="clear sv-30"></div>
            {this.renderButton()}
            {this.renderButtonRefund()}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common','billing'])(ModalPayment);