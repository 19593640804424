import React, { Component } from 'react';
import ListClient from '../List/client';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



const DataInputClient = [
  {
    id: 'payment',
    title: 'widgets:form.client.payment.title',
    line1: 'widgets:form.client.payment.line1',
    line2: 'widgets:form.client.payment.line2',
    error: 'widgets:form.client.payment.error',
  },
  {
    id: 'habit',
    title: 'widgets:form.client.habit.title',
    line1: 'widgets:form.client.habit.line1',
    line2: 'widgets:form.client.habit.line2',
    error: 'widgets:form.client.habit.error',
  },
  {
    id: 'nutrition',
    title: 'widgets:form.client.nutrition.title',
    line1: 'widgets:form.client.nutrition.line1',
    line2: 'widgets:form.client.nutrition.line2',
    error: 'widgets:form.client.nutrition.error',
  },
  {
    id: 'invoice',
    title: 'widgets:form.client.invoice.title',
    line1: 'widgets:form.client.invoice.line1',
    line2: 'widgets:form.client.invoice.line2',
    error: 'widgets:form.client.invoice.error',
  },
  {
    id: 'debit',
    title: 'widgets:form.client.debit.title',
    line1: 'widgets:form.client.debit.line1',
    line2: 'widgets:form.client.debit.line2',
    error: 'widgets:form.client.debit.error',
  },
  {
    id: 'session',
    title: 'widgets:form.client.session.title',
    line1: 'widgets:form.client.session.line1',
    line2: 'widgets:form.client.session.line2',
    error: 'widgets:form.client.session.error',
  },
  {
    id: 'recurring',
    title: 'widgets:form.client.recurring.title',
    line1: 'widgets:form.client.recurring.line1',
    line2: 'widgets:form.client.recurring.line2',
    error: 'widgets:form.client.recurring.error',
  },
]


class InputClient extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      client: props.client,
      preselected: props.presel ?? false,
      error: props.error ?? false,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
      client: props.client,
      preselected: props.presel ?? false,
      error: props.error ?? false,
    }
  }


  renderClient() {
    if(this.state.client === null) {
      var data = null
      for(var item of DataInputClient) {
        if(item.id === this.state.id) {
          data = item
        }
      }
      if(data !== null) {
        return (
          <ListClient title={lang.t(data.title)} line1={lang.t(data.line1)} line2={lang.t(data.line2)} type='placeholder' clickElement={() => this.props.clickElement()}/>
        )
      }
    } else {
      if(this.state.preselected) {
        return (
          <ListClient client={this.state.client} type='static'/>
        )
      } else {
        return (
          <ListClient client={this.state.client} clickElement={() => this.props.clickElement()}/>
        )
      }
      
    }
  }


  renderError() {
    if(this.state.error) {
      var error = 'Error'
      for(var item of DataInputClient) {
        if(item.id === this.state.id) {
          error = item.error
        }
      }
      return (
        <div className="form">
          <p className="error">{lang.t(error)}</p>
        </div>
      )
    }
  }


  render() {
    return (
      <div>
        <label>{lang.t('widgets:form.client.label')}</label>
        {this.renderClient()}
        {this.renderError()}
      </div>
    )
  }
}


export default withTranslation(['widgets'])(InputClient);