import React, { Component } from 'react';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import HelperCal from '../../helper/Calendar';
import HelperPage from '../../helper/Page';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperBusiness from '../../helper/Business';
import ListEmpty from '../../components/List/empty';
import ModalVideo from '../../components/Modals/video';
import WidgetChartGraph from '../../components/Widgets/chart-graph';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class BenchmarkPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      cid: '',
      bid: '',
      item: null,
      list: [],
      back: '',
      showModalVideo: false,
    };
  }
z

  componentDidMount() {
    HelperPage.initializeForm('Benchmark Program - Client Details - PT Mate')
    HelperPage.checkMessage()
    this._isMounted = true

    var tmp = this.props.location.pathname.split('/')
    this.setState({
      bid: tmp[tmp.length-1],
      id: tmp[tmp.length-3],
    })
    global.currentClient = tmp[tmp.length-3]
    EventEmitter.subscribe('clientsLoaded', (event) => {
      if(!this.state.deleted) {
        this.getClient(tmp[tmp.length-3], tmp[tmp.length-1])
      }
    })
    var arr = this.props.location.pathname.split('/')
    if(this.state.id === '') {
      global.currentClient = arr[arr.length-2]
    }
    if(!this.state.deleted) {
      this.getClient(tmp[tmp.length-3], tmp[tmp.length-1])
    }

    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getClient(id, aid) {
    if(this._isMounted) {
      var tmp3 = null
      var arr = this.props.location.pathname.split('/')
      global.currentClient = arr[arr.length-3]
      for(var client of global.clients) {
        if(client.id === global.currentClient) {
          for(var ass of client.assessments) {
            if(ass.id === aid) {
              tmp3 = ass
            }
          }

          this.setState({
            id: client.id,
            cid: client.data.uid,
            bid: arr[arr.length-1],
            item: tmp3,
            name: client.data.name,
            back: '/clients/'+client.id+'/progress',
          }, () => {
              this.configureData()
          });
          
        }
      }
    }
  }


  configureData() {
    if(this._isMounted) {
      var item = null
      var list = []
      for(var sess of global.sessions) {
        var add1 = false
        if(sess.data.client === this.state.id && !sess.data.group) {
          add1 = true
        }
        if(sess.data.group && sess.data.clients !== undefined) {
          if(sess.data.clients.indexOf(this.state.id) !== -1) {
            add1 = true
          }
        }
        if(sess.program.length > 0 && add1) {
          if(sess.program[0].id === this.state.bid) {
            item = sess.program[0]
            list.push(sess)
          }
        }
      }
      for(var arch of global.archive) {
        var add2 = false
        if(arch.data.client === this.state.id && !arch.data.group) {
          add2 = true
        }
        if(arch.data.group && arch.data.clients !== undefined) {
          if(arch.data.clients.indexOf(this.state.id) !== -1) {
            add2 = true
          }
        }
        if(arch.program.length > 0 && add2) {
          if(arch.program[0].id === this.state.bid) {
            item = arch.program[0]
            list.push(arch)
          }
        }
      }
      for(var sess2 of global.sessionsTraining) {
        var add3 = false
        if(sess.data.uid === this.state.cid) {
          add3 = true
        }
        if(sess2.program.length > 0 && add3) {
          if(sess2.program[0].id === this.state.bid) {
            item = sess2.program[0]
            list.push(sess2)
          }
        }
      }
      for(var arch2 of global.archiveTraining) {
        var add4 = false
        if(sess.data.uid === this.state.cid) {
          add4 = true
        }
        if(arch2.program.length > 0 && add4) {
          if(arch2.program[0].id === this.state.bid) {
            item = arch2.program[0]
            list.push(arch2)
          }
        }
      }
      this.setState({
        item: item,
        list: list,
      })
    }
  }


  renderSetsName(item) {
    var label = lang.t('program:label.set')
    if(item.exId.length > 1) {
      var prev = ''
      var multiple = true
      for(var ex of item.exId) {
        if(ex !== prev && prev !== '') {
          label = lang.t('program:label.superset')
          multiple = false
        }
        if(ex === prev && prev !== '') {
          label = lang.t('program:label.dropset')
        }
        prev = ex
      }
      if(multiple) {
        label = lang.t('program:label.dropset')
      } else {
        label = lang.t('program:label.superset')
      }
    }
    return label
  }


  renderBlockType(item) {
    var label = ''
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' '+lang.t('program:label.rounds')+' '+lang.t('program:label.of')+' '+HelperCal.getDurationMi(parseInt(item.exWork[0]))
      if(item.emom) {
        label = ' - '+item.rounds+' '+lang.t('program:label.rounds')+' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' ('+lang.t('program:label.allatthesametime')+')'
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' '+lang.t('program:label.rounds')
      if(item.rounds === 1) {
        label = ' - 1 '+lang.t('program:label.round')
      }
      if(item.type === 2 && item.emom) { 
        if(item.exRest[0] === 0) {
          label += ' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('program:label.worknorest')
        } else {
          label += ' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('program:label.workand')+' '+HelperCal.getDurationMin(parseInt(item.exRest[0]))+' '+lang.t('program:label.rest-s')
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.renderSetsName(item)+'s'
      if(item.rounds === 1) {
        label = ' - 1 '+this.renderSetsName(item)
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' '+lang.t('program:label.rounds')+' - '+lang.t('program:label.cap')+': '+HelperCal.getDurationMin(item.rounds)
      }
    }
    return label
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalVideo: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  getTarget(item, index) {
    var label = ''
    var reps = 'reps'
    if(item.exReps[index] !== 0) {
      if(item.exTool[index] === 6 || item.exTool[index] === 7 || item.exTool[index] === 25) {
        reps = 'm'
      }
      if(item.exTool[index] === 27) {
        reps = 'cal'
      }
      label = item.exReps[index]+' '+reps
    }
    if(item.exWeight[index] !== 0) {
      if(item.exWeightType[index] === undefined || item.exWeightType[index] === 'per') {
        label += ' '+lang.t('program:label.at')+' '+item.exWeight[index]+'%'
      } else {
        label += ' '+lang.t('program:label.with')+' '+item.exWeight[index]+'kg'
      }
    }
    if(label === '') {
      label = '-'
    }
    return label
  }

  
  getWeight(item, index) {
    var label = '-'
    var val = 0
    if(item.data.exResWeight[index] !== undefined && !item.group) {
      if(parseFloat(item.data.exResWeight[index]) !== 0) {
        label = item.data.exResWeight[index]+' kg'
        val = item.data.exResWeight[index]
      }
      if(item.data.exResWeightRounds !== undefined ) {
        if(item.data.exResWeightRounds[index] !== '') {
          label = item.data.exResWeightRounds[index]+' kg'
        }
      }
    }
    if(item.data.exResWeightGroup !== undefined && item.group) {
      var ar = item.data.exResWeightGroup[index].split('-')
      ar.splice(0,1)
      if(ar.length > item.client) {
        if(ar[item.client] !== '' && ar[item.client] !== '0' && ar[item.client] !== '0.0') {
          label = ar[item.client]+' kg'
          val = ar[item.client]
        }
        
      }
      if(item.data.exResWeightRounds !== undefined && item.data.exResWeightRounds !== '') {
        var ar2 = item.data.exResWeightRounds[index].split('|')
        if(ar2.length > item.client) {
          if(ar2[item.client] !== '') {
            label = ar2[item.client]+' kg'
          }
        }
      }
    }
    if(global.exToolsWeight.indexOf(item.data.exTool[index]) === -1) {
      label = ''
      val = 0
    }
    return [label, val]
  }


  getReps(item, index) {
    var label = '-'
    if(item.data.exResReps !== undefined && !item.group) {
      if(parseInt(item.data.exResReps[index]) !== 0) {
        label = item.data.exResReps[index]
        if(item.data.exResRepsRounds[index] !== undefined && item.data.exResRepsRounds[index] !== '') {
          label = item.data.exResRepsRounds[index]
        }
      }
    }
    if(item.data.exResRepsGroup !== undefined && item.group) {
      var ar = item.data.exResRepsGroup[index].split('-')
      ar.splice(0,1)
      if(ar.length > item.client) {
        if(ar[item.client] !== '' && ar[item.client] !== '0' && ar[item.client] !== '0.0') {
          label = ar[item.client]
        }
        
      }
      if(item.data.exResRepsRounds !== undefined && item.data.exResRepsRounds !== '') {
        var ar2 = item.data.exResRepsRounds[index].split('|')
        if(ar2.length > item.client) {
          if(ar2[item.client] !== '') {
            label = ar2[item.client]
          }
        }
      }
    }
    if((item.data.exTool[index] === 6 || item.data.exTool[index] === 7) && label !== '-') {
      label += ' m'
    }
    return label
  }


  getRepsTitle(tool) {
    var label = 'Reps'
    if(tool === 6 || tool === 7 || tool === 25) {
      label = lang.t('program:form.distance')
    }
    if(tool === 27) {
      label = lang.t('program:form.tracks.label3')
    }
    return label
  }


  getRounds(item, type) {
    var number = 0
    var total = 0
    var should = 0
    for(var i=0; i<item.data.exReps.length; i++) {
      if(!item.group && item.data.exResReps.length > i) {
        total += item.data.exResReps[i]
      }
      if(item.group && item.data.exResRepsGroup.length > i) {
        var ar = item.data.exResRepsGroup[i].split('-')
        ar.splice(0,1)
        if(ar[item.client] !== '') {
          total += parseInt(ar[item.client])
        }
      }
      should += item.data.exReps[i]
    }
    number = Math.floor(total/should)
    if(type === 'reps') {
      number = total-(number*should)
    }
    return number
  }


  renderGraph(block, ex) {
    var tmp = []
    var lst = []
    for(var items of this.state.list) {
      var client = 0
      if(items.data.clients !== undefined) {
        for(var i=0; i<items.data.clients.length; i++) {
          if(items.data.clients[i] === this.state.id) {
            client = i
          }
        }
      }
      for(var bl of items.program[0].blocks) {
        if(bl.id === block.id) {
          lst.push({data: bl.data, date: items.data.date, group: items.data.group, client})
        }
      }
    }
    for(var item of lst) {
      tmp.push({
        "x": Moment(item.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YY'),
        "y": parseInt(this.getWeight(item, ex)[1]),
      })
    }
    var tmp2 = [{
      "id": "Weight",
      "color": global.themeColors[0],
      data: tmp
    }]
    return (
      <div className="graph">
        <WidgetChartGraph data={tmp2} axis='' curve='linear'/>
      </div>
    )
  }


  renderRowsAmrap(block) {
    var lst = []
    for(var items of this.state.list) {
      var client = 0
      if(items.data.clients !== undefined) {
        for(var i=0; i<items.data.clients.length; i++) {
          if(items.data.clients[i] === this.state.id) {
            client = i
          }
        }
      }
      for(var bl of items.program[0].blocks) {
        if(bl.id === block.id) {
          lst.push({data: bl.data, date: items.data.date, group: items.data.group, client})
        }
      }
    }
    lst.sort((a,b) => Moment(a.date, 'DD/MM/YYYY HH:mm') - Moment(b.date, 'DD/MM/YYYY HH:mm'))
    return (
      <div>
        {lst.map((item, index) => (
          <div>
            <div className="col-4">{Moment(item.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')}</div>
            <div className="col-4">{this.getRounds(item, 'rounds')}</div>
            <div className="col-4">{this.getRounds(item, 'reps')}</div>
            <div className="clear"></div>
          </div>
        ))}
      </div>
    )
  }


  renderRows(block, ex) {
    var lst = []
    for(var items of this.state.list) {
      var client = 0
      if(items.data.clients !== undefined) {
        for(var i=0; i<items.data.clients.length; i++) {
          if(items.data.clients[i] === this.state.id) {
            client = i
          }
        }
      }
      for(var bl of items.program[0].blocks) {
        if(bl.id === block.id) {
          lst.push({data: bl.data, date: items.data.date, group: items.data.group, client})
        }
      }
    }
    lst.sort((a,b) => Moment(b.date, 'DD/MM/YYYY HH:mm') - Moment(a.date, 'DD/MM/YYYY HH:mm'))
    if(block.data.type === 0) {
      return (
        <div>
          {lst.map((item, index) => (
            <div className="row">
              <div className="col-4">{Moment(item.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')}</div>
              <div className="col-8">{this.getWeight(item, ex)[0]}</div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    } else {
      return (
        <div>
          {lst.map((item, index) => (
            <div className="row">
              <div className="col-4">{Moment(item.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')}</div>
              <div className="col-4">{this.getReps(item, ex)}</div>
              <div className="col-4">{this.getWeight(item, ex)[0]}</div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderExTop(block, num, index) {
    if(block.type !== 0 && block.logResults) {
      if(global.exToolsWeight.indexOf(block.exTool[index]) !== -1) {
        return (
          <div className="info">
            <div className="col-4"><label>{lang.t('clients:label.date')}</label></div>
            <div className="col-4"><label>Reps</label></div>
            <div className="col-4"><label>{lang.t('clients:label.weight')}</label></div>
            <div className="clear"></div>
            {this.renderRows(this.state.item.blocks[num], index)}
          </div>
        )
      } else {
        return (
          <div className="info">
            <div className="col-4"><label>{lang.t('clients:label.date')}</label></div>
            <div className="col-8"><label>{this.getRepsTitle(this.state.item.blocks[num].data.exTool[index])}</label></div>
            <div className="clear"></div>
            {this.renderRows(this.state.item.blocks[num], index)}
          </div>
        )
      }
    } else if(block.type === 0 && block.logResults && global.exToolsWeight.indexOf(block.exTool[index]) !== -1) {
      return (
        <div className="info">
          <div className="col-4"><label>{lang.t('clients:label.date')}</label></div>
          <div className="col-8"><label>{lang.t('clients:label.weight')}</label></div>
          <div className="clear"></div>
          {this.renderRows(this.state.item.blocks[num], index)}
        </div>
      )
    }
  }


  renderTime(num) {
    if(this.state.item.blocks[num].data.type === 0) {
      return (
        <div className="col-4">
          <div className="box">
            <h4 className="mb-20">{lang.t('clients:label.roundsandreps')}</h4>
            <div className="info">
              <div className="col-4"><label>{lang.t('clients:label.date')}</label></div>
              <div className="col-4"><label>{lang.t('clients:label.rounds')}</label></div>
              <div className="col-4"><label>Reps</label></div>
              <div className="clear"></div>
              {this.renderRowsAmrap(this.state.item.blocks[num])}
            </div>
          </div>
        </div>
      )
    }
  }


  renderExercises(num) {
    var block = this.state.item.blocks[num].data
    return (
      <div>
        {block.exName.map((item, index) => (
          <div className="col-4" key={index}>
            <div className="box bench mb-20">
              <h4 className="mb-30">{item}</h4>
              {this.renderGraph(this.state.item.blocks[num], index)}
              <div className="sv-30"></div>
              <div className="info">
                <label>{lang.t('clients:label.target')}</label>
                <p>{this.getTarget(block, index)}</p>
              </div>
              <div className="clear mb-10"></div>
              {this.renderExTop(block, num, index)}
              <div className="clear"></div>
            </div>
          </div>
        ))}
      </div>
    )
  }


  renderNotes(item) {
    if(item.data.notes !== '') {
      return (
        <div className="ex-notes clear">
          <label>{lang.t('clients:label.notes')}</label>
          <p style={{whiteSpace: 'pre-wrap'}}>{item.data.notes}</p>
        </div>
      )
    }
  }


  renderBlockTitle(item) {
    if(item.data.name !== '' && item.data.name !== undefined) {
      return item.data.name
    } else {
      return lang.t(global.exCats[item.data.cat])
    }
  }


  renderBlocks() {
    return (
      <div>
        {this.state.item.blocks.map((item, index) => (
          <div className="clear mb-50" key={index}>
            <label>{this.renderBlockTitle(item)}</label>
            <h3 className="clear lft mb-20">{lang.t(global.proTypes[item.data.type])} {this.renderBlockType(item.data)}</h3>
            <div className="clear"></div>
            {this.renderTime(index)}
            {this.renderExercises(index)}
            {this.renderNotes(item)}
            <div className="clear"></div>
          </div>
        ))}
      </div>
    )
  }


  renderButtonVideo() {
    if(this.state.item.data.video !== undefined && this.state.item.data.video !== "") {
      return <button className="btn tertiary" onClick={() => this.setState({showModalVideo: true})}>{lang.t('clients:button.watchvideo')}</button>
    }
  }


  renderInfo() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content mt-20">
          <div className="program-header mb-60">
            <div className={'icon large lft mr-20 '+HelperCal.getColor(this.state.item.data.time)}>
              <p>{this.state.item.data.time}'</p>
            </div>
            <div className="main">
              <h2>{this.state.item.data.name}</h2>
              <p className="mb-10">{this.state.item.data.desc}</p>
              {this.renderButtonVideo()}
            </div>
            <div className="clear"></div>
          </div>
          {this.renderBlocks()}
        </div>
        <Header title='Benchmark Program' link={'/clients/'+this.state.id+'/progress'}/>
        <Navigation active='clients' />
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('assistant,assistant2,trainer')) {
      if(this.state.item !== null) {
        return this.renderInfo()
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <Header title={lang.t('clients:title.benchmarkprogram')} link={'/clients/'+this.state.id+'/progress'}/>
            <Navigation active='clients' />
            <ModalVideo show={this.state.showModalVideo} url={this.state.item === null ? '' : this.state.item.data.video} onHide={() => this.hideModals()}/>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <ListEmpty id='benchmark-locked' type='simple'/>
          </div>
          <Header title={lang.t('clients:title.benchmarkprogram')} link={'/clients/'+this.state.id+'/progress'}/>
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','program'])(withRouter(withAuthorization(condition)(BenchmarkPage)));