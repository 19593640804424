import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import Connector from '../../data/Connector';
import HelperCal from '../../helper/Calendar';
import HelperEmpty from '../../helper/EmptyLarge';
import EventEmitter from '../../helper/Emitter';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class WidgetProgram extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      session: props.session
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      session: props.session
    }
  }


  saveToPrograms() {
    global.programLocked = true
    Firebase.database().ref('/workouts/'+global.uid+'/'+this.state.session.program[0].id).update(
      this.state.session.program[0].data
    ).then((data)=>{
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.programsaved'));
      global.programLocked = false
      Connector.loadPrograms((result) => {})
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  getSetsNameStatic(item) {
    var s = ''
    if(item.rounds > 1) {
      s = 's'
    }
    var label = lang.t('program:label.set'+s)
    if(item.exId.length > 1) {
      var prev = ''
      var multiple = true
      for(var ex of item.exId) {
        if(ex !== prev && prev !== '') {
          label = lang.t('program:label.superset'+s)
          multiple = false
        }
        if(ex === prev && prev !== '') {
          label = lang.t('program:label.dropset'+s)
        }
        prev = ex
      }
      if(multiple) {
        label = lang.t('program:label.dropset'+s)
      } else {
        label = lang.t('program:label.superset'+s)
      }
    }
    return label
  }


  getBlockType(item) {
    var label = ''
    var s = ''
    if(item.rounds > 1) {
      s = 's'
    }
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' '+lang.t('program:label.round'+s)
      if(item.emom) {
        label = ' - '+item.rounds+' '+lang.t('program:label.round'+s)+' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' '+lang.t('program:label.rounds')
      if(item.rounds === 1) {
        label = ' - 1 '+lang.t('program:label.round')
      }
      if(item.type === 2 && item.emom) { 
        if(item.exRest[0] === 0) {
          label += ' of '+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('program:label.worknorest')
        } else {
          label += ' of '+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('program:label.workand')+' '+HelperCal.getDurationMin(parseInt(item.exRest[0]))+' '+lang.t('program:label.rest')
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.getSetsNameStatic(item)+'s'
      if(item.rounds === 1) {
        label = ' - 1 '+this.getSetsNameStatic(item)
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' '+lang.t('program:label.rounds')+' - '+lang.t('program:label.cap')+': '+HelperCal.getDurationMin(item.rounds)
      }
    }
    return label
  }


  getBlockTitle(item) {
    if(item.data.name !== '' && item.data.name !== undefined) {
      return item.data.name
    } else {
      return lang.t(global.exCats[item.data.cat])
    }
  }


  renderButtons() {
    var date2 = parseInt(Moment(this.state.session.data.timestamp, 'X').add(this.state.session.data.duration, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(this.state.session.program.length !== 0) {
      if(now < date2) {
        return (
          <div className="mb-20">
            <div className="col-6">
              <button className="btn secondary small col-6" onClick={() => this.props.clickEdit()}>{lang.t('common:button.edit')}</button>
            </div>
            <div className="col-6">
              <button className="btn secondary small col-6" onClick={() => this.props.clickReplace()}>{lang.t('program:button.replace')}</button>
            </div>
            <div className="clear"></div>
          </div>
        )
      } else {
        if(this.state.session.data.attendance === 3) {
          return (
            <div>
              <div className="sv-10"></div>
              <button className="btn secondary small" onClick={() => this.props.clickResults()}>{lang.t('program:button.viewandeditsessionresults')}</button>
              <div className="sv-20"></div>
              <button className="btn tertiary small edit width-12" onClick={() => this.props.clickEdit()}>{lang.t('program:button.editprogram')}</button>
              <div className="sv-10"></div>
            </div>
          )
        } else {
          return <p className="small" style={{textAlign: 'center', whiteSpace: 'pre-wrap'}}>{lang.t('program:session.confirmattendance')}</p>
        }
      }
    } else {
      //if(now < date2) {
        return (
          <div className="mb-20">
            <div className="col-6">
              <button className="btn secondary small col-6" onClick={() => this.props.clickList()}>{lang.t('program:button.selectfromlist')}</button>
            </div>
            <div className="col-6">
              <button className="btn secondary small col-6" onClick={() => this.props.clickNew()}>{lang.t('program:button.createnew')}</button>
            </div>
            <div className="clear"></div>
          </div>
        )
      //}
    }
  }


  renderDesc() {
    if(this.state.session.program[0].data.desc !== '' && this.state.session.program[0].data.desc !== undefined) {
      return (
        <p className="clear small mb-10">{this.state.session.program[0].data.desc}</p>
      )
    }
  }


  render() {
    if(this.state.session.program.length !== 0) {
      var list = this.state.session.program[0].blocks
      return (
        <div className="session-program">
          <div className="sv-30"></div>
          <h5 className="mb-10">{this.state.session.program[0].data.name} ({this.state.session.program[0].data.time} min) <button className="btn tertiary small rgt" onClick={() => this.saveToPrograms()}>{lang.t('program:button.savetomyprograms')}</button></h5>
          {this.renderDesc()}
          {list.map(item => (
            <div className="box highlight list simple mb-10" key={item.id} onClick={() => this.props.clickElement(item.id)}>
              <p className="lft"><strong>{this.getBlockTitle(item)}</strong><br/>{lang.t(global.proTypes[item.data.type])} {this.getBlockType(item.data)}</p>
              <button className="btn tertiary small rgt">View</button>
              <div className="clear"></div>
            </div>
          ))}
          <div className="clear sv-10"></div>
          {this.renderButtons()}
          <div className="clear sv-20"></div>
        </div>
      )
    } else {
      var title = lang.t('program:session.notassigned.title')
      var label = this.state.session.data.group && (!this.state.session.data.availability || this.state.session.data.availability === undefined) ? lang.t('program:session.notassigned.text2') : lang.t('program:session.notassigned.text')
      if(parseInt(Moment().format('X')) > parseInt(Moment(this.state.session.data.timestamp, 'X').add(this.state.session.data.duration, 'minutes').format('X'))) {
        title = this.state.session.data.group && (!this.state.session.data.availability || this.state.session.data.availability === undefined) ? lang.t('program:session.unavailable.title2') : lang.t('program:session.unavailable.title')
        label = this.state.session.data.group && (!this.state.session.data.availability || this.state.session.data.availability === undefined) ? lang.t('program:session.unavailable.text2') : lang.t('program:session.unavailable.text')
      }
      return (
        <div className="session-program mb-20">
          <div className="image empty large" style={{transform: 'scale(.8)'}}>
            <div className="illustration">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmpty.renderImage('programs')}
            </div>
            <h3 className="mb-20 mt-20">{title}</h3>
              <p>{label}</p>
          </div>
          {this.renderButtons()}
          <div className="clear"></div>
        </div>
      )
    }
  }
}


export default withTranslation(['program','common','messaging'])(WidgetProgram);