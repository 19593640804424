import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import { Link } from 'react-router-dom';
import Cal from '../../helper/Calendar';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ModalItems extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      multiple: props.multiple ?? false,
      items: props.items ?? global.programs,
      itemsList: props.items ?? global.programs,
      selected: props.selected ?? [],
      search: '',
      all: false,
      type: props.type ?? 'groups',
      title: props.title ?? lang.t('modals:items.title'),
      button: props.button ?? lang.t('modals:items.button'),
      empty: props.empty ?? lang.t('modals:items.empty')
    };
    if(props.show) {
      this.show()
      this.setList()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      multiple: props.multiple ?? false,
      items: props.items ?? global.programs,
      selected: props.selected ?? this.state.selected,
      type: props.type ?? 'groups',
      title: props.title ?? lang.t('modals:items.title'),
      button: props.button ?? lang.t('modals:items.button'),
      empty: props.empty ?? lang.t('modals:items.empty')
    }, () => {
      if(this.state.show) {
        this.show()
        this.setList()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
      });
    }, 100);
  }


  hide() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  getTitle() {
    var title = this.state.title
    if(this.state.multiple && this.state.selected.length > 0) {
      title = this.state.selected.length+' '+lang.t('modals:clients.title.selected')
    }
    return title
  }


  getSessionTitle(item) {
    var label = lang.t('modals:items.groupsession')
    if(item.data.client !== '') {
      label = item.data.client
    }
    if(!item.data.group) {
      label = lang.t('common:label.deletedclient')
      for(var client of global.clients) {
        if(client.id === item.data.client) {
          label = client.name
        }
      }
    }
    return label
  }


  getMembership(item) {
    var num = lang.t('modals:items.unlimited')
    var s = 's'
    if(item.data.sessions !== 0 && item.data.sessions !== undefined) {
      num = item.data.sessions
      if(item.data.sessions === 1) {
        s = ''
      }
    }
    var label = ' '+lang.t('modals:items.11session'+s+'-s')
    if(item.data.group || item.data.stype === 'group') {
      label = ' '+lang.t('modals:items.groupsession'+s+'-s')
    }
    if(item.data.stype === 'both') {
      label = ' '+lang.t('modals:items.groupand')+' '+item.data.sessions11+' '+lang.t('modals:items.11-sessions')
    }
    return num+label
  }


  getStaff(item) {
    var label = lang.t('modals:role.assistanttrainer')
    if(item.data.role === 'trainer' || item.data.role === 'trainer2') {
      label = lang.t('modals:role.trainer')
    }
    if(item.data.role === 'finance') {
      label = lang.t('modals:role.finance')
    }
    if(item.data.role === 'marketing') {
      label = 'Marketing'
    }
    if(item.data.role === 'admin') {
      label = lang.t('modals:role.admin')
    }
    if(item.data.role === 'cs') {
      label = lang.t('modals:role.cs')
    }
    if(item.data.role === 'manager') {
      label = lang.t('modals:role.manager')
    }
    return label
  }


  getClients(item) {
    var label = lang.t('modals:clients.empty')
    if(item.data.clients !== undefined) {
      label = item.data.clients.length+' '+lang.t('modals:items.clients')
      if(item.data.clients.length === 1) {
        label = '1 '+lang.t('modals:items.client')
      }
    }
    return label
  }



  // Filters -----------------------------------------------------



  filterItems = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.setList()
    })
  }


  getSelected(item) {
    var label = 'list bare'
    for(var sel of this.state.selected) {
      if(sel === item) {
        label = 'list bare selected'
      }
    }
    return label
  }


  selectAll() {
    if(this.state.all) {
      this.setState({
        selected: [],
        all: false
      })
    } else {
      var tmp = []
      for(var item of this.state.itemsList) {
        if((item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && this.state.search !== '') || this.state.search === '') {
          tmp.push(item)
        }
      }
      this.setState({
        selected: tmp,
        all: true
      })
    }
  }


  setList() {
    var tmp = []
    for(var item of this.state.items) {
      if(item.data.name !== undefined) {
        if((item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && this.state.search !== '') || this.state.search === '') {
          tmp.push(item)
        }
      } else {
        tmp.push(item)
      }
    }
    this.setState({itemsList: tmp})
  }


  clickElement(item) {
    if(this.state.multiple) {
      var tmp = this.state.selected
      var add = true
      if(tmp.length > 0) {
        for(var i=0; i<tmp.length; i++) {
          if(tmp[i] === item) {
            tmp.splice(i, 1)
            add = false
          }
        }
      }
      
      if(add) {
        tmp.push(item)
      }
      this.setState({
        selected: tmp
      })
    } else {
      this.hide()
      if(this.state.type === 'sessions' || this.state.type === 'bookings') {
        var link = (item.data.group ? '/calendar/group-session/'+item.id : '/calendar/session/'+item.id)
        this.props.clickElement(link)
      } else {
        this.props.clickElement(item)
      }
      
    }
  }



  // Display stuff -----------------------------------------------------



  renderSearch() {
    var width = 330
    if(this.state.multiple) {
      width -= 40
    }
    if(this.state.type !== 'notes' && this.state.type !== 'sessions' && this.state.type !== 'bookings' && this.state.type !== 'packs' && this.state.type !== 'memberships' && this.state.type !== 'staff' && this.state.type !== 'locations') {
      return (
        <div>
          <div className={this.state.multiple ? 'lft' : 'none'}>
            <div className={this.state.all ? 'checkbox container active' : 'checkbox container'} onClick={() => this.selectAll()}>
              <div className="check"><div className="inner"></div></div>
            </div>
          </div>
          <div style={{width: width,float:'right'}}>
            <input type="text" placeholder={lang.t('modals:clients.filterbyname')} value={this.state.search} onChange={(val) => this.filterItems(val)}/>
          </div>
        </div>
      )
    }
  }


  renderButton() {
    if(this.state.multiple) {
      return (
        <button className="btn primary" onClick={() => this.props.clickMainButton(this.state.selected)}>{this.state.button}</button>
      )
    }
  }


  renderSessionIcon(type) {
    if(type === 'Scheduled') {
      return (
        <div className="icon secondary">
          <div className="inner session"></div>
        </div>
      )
    } else {
      return (
        <div className="icon primary">
          <div className="inner group"></div>
        </div>
      )
    }
  }


  renderProgramInfo(item) {
    if(item.data.benchmark) {
      return (
        <p>{item.blocks.length} {item.blocks.length > 1 ? lang.t('modals:items.blocks') : lang.t('modals:items.block')}<br/><span className="pill primary">{lang.t('modals:items.benchmark')}</span></p>
      )
    } else {
      return (
        <p>{item.blocks.length} {item.blocks.length > 1 ? lang.t('modals:items.blocks') : lang.t('modals:items.block')}<br/>{item.data.exercises} {item.data.exercises === 1 ? lang.t('modals:items.movement') :lang.t('modals:items.movements')}</p>
      )
    }
  }


  renderPackName(item) {
    var label = lang.t('modals:items.groupsessions')
    if(!item.data.group) {
      label = lang.t('modals:items.11sessions')
    }
    if(item.data.name !== undefined && item.data.name !== '') {
      label = item.data.name
    }
    return label
  }


  renderContent() {
    if(this.state.itemsList.length === 0) {
      return (
        <div className={this.state.multiple ? 'scroll withbutton' : 'scroll'}>
          <div className="empty small mt-30">
            <h4>{this.state.empty}</h4>
          </div>
        </div>
      )
    } else {
      var list = this.state.itemsList
      if(this.state.type !== 'sessions' && this.state.type !== 'notes' && this.state.type !== 'bookings' && this.state.type !== 'packs') {
        list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      } else if(this.state.type === 'packs') {
        list.sort((a,b) => Moment(a.data.expires, 'X') - Moment(b.data.expires, 'X'));
      }
      if(this.state.type === 'groups') {
        return (
          <div className={this.state.multiple ? 'scroll withbutton' : 'scroll'}>
            {list.map(item => (
              <div className={this.getSelected(item)} onClick={() => this.clickElement(item)} key={item.id}>
                <div className="icon grey">
                  <div className="inner group"></div>
                </div>
                <div className="main">
                  <h4>{item.data.name}</h4>
                  <p>{item.data.clients.length} {item.data.clients.length === 1 ? lang.t('modals:items.client') : lang.t('modals:items.clients')}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      } else if(this.state.type === 'notes') {
        list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'))
        return (
          <div>
            {list.map(item => (
              <div className="list bare static" key={item.id}>
                <div className="icon quarternary">
                  <div className="inner note"></div>
                </div>
                <div className="main">
                  <h4>{item.data.title}</h4>
                  <p>{item.data.desc}<br/><span style={{fontSize:11}}>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('llll')}</span></p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      } else if(this.state.type === 'plans') {
        return (
          <div className={this.state.multiple ? 'scroll withbutton' : 'scroll'}>
            {list.map(item => (
              <div className="list bare" key={item.id} onClick={() => this.clickElement(item)}>
                <div className='icon primary'>
                  <p>{item.weeks.length}</p>
                </div>
                <div className="main">
                  <h4>{item.data.name}</h4>
                  <p>{item.weeks.length} {item.weeks.length === 1 ? lang.t('modals:items.week') : lang.t('modals:items.weeks')}<br/>{item.programs.length} {item.programs.length === 1 ? lang.t('modals:items.program') : lang.t('modals:items.programs')}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      } else if(this.state.type === 'sessions') {
        return (
          <div className={this.state.multiple ? 'scroll withbutton' : 'scroll'}>
            {list.map(item => (
              <div className="client-list" onClick={() => this.clickElement(item)} key={item.id}>
                <div className={"avatar unit "+Cal.getColor(item.data.duration)}><p>{item.data.duration}<br/><span>min</span></p></div>
                <div className="text">
                  <h4>{this.getSessionTitle(item)}</h4>
                  <p>{Moment(item.data.timestamp, 'X').locale(lang.language).format('llll')}<br/>{item.data.duration} min</p>
                </div>
              </div>
            ))}
          </div>
        )
      } else if(this.state.type === 'bookings') {
        return (
          <div className={this.state.multiple ? 'scroll withbutton' : 'scroll'}>
            {list.map(item => (
              <div className="list bare static" key={item.id}>
                <Link to={item.data.link}>
                  {this.renderSessionIcon(item.data.type)}
                  <div className="main">
                    <h4>{item.data.name}</h4>
                    <p>{Cal.getSpecialDate(item)}</p>
                  </div>
                  <div className="clear"></div>
                </Link>
              </div>
            ))}
          </div>
        )
      } else if(this.state.type === 'packs') {
        return (
          <div className="scroll added">
            {list.map(item => (
              <div className="list bare" key={item.id} onClick={() => this.clickElement(item)}>
                <div className='icon secondary'>
                  <div className='inner pack'></div>
                </div>
                <div className="main">
                  <h4>{this.renderPackName(item)}</h4>
                  <p>{item.data.sessionsTotal === 0 ? lang.t('modals:items.unused') : (item.data.sessionsTotal)+' '+lang.t('modals:items.of')+' '+item.data.sessionsPaid+' '+lang.t('modals:items.used')}<br/>{Moment(item.data.expires, 'X') > Moment() ? lang.t('modals:items.expires') : lang.t('modals:items.expired')} {Moment(item.data.expires, 'X').locale(lang.language).format('ddd, D MMM YYYY')}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      } else if(this.state.type === 'memberships') {
        return (
          <div className="scroll added">
            {list.map(item => (
              <div className={this.getSelected(item)} onClick={() => this.clickElement(item)} key={item.id}>
                <div className='icon secondary'>
                  <div className='inner pack'></div>
                </div>
                <div className="main">
                  <h4>{item.data.name}</h4>
                  <p>{this.getMembership(item)}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      } else if(this.state.type === 'staff') {
        return (
          <div className="scroll added">
            {list.map(item => (
              <div className={this.getSelected(item)} onClick={() => this.clickElement(item)} key={item.id}>
                <div className='icon quarternary'>
                  <div className='inner group'></div>
                </div>
                <div className="main">
                  <h4>{item.data.name}</h4>
                  <p>{this.getStaff(item)}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      } else if(this.state.type === 'locations') {
        return (
          <div className="scroll added">
            {list.map(item => (
              <div className={this.getSelected(item)} onClick={() => this.clickElement(item)} key={item.id}>
                <div className='icon quarternary'>
                  <div className='inner location'></div>
                </div>
                <div className="main">
                  <h4>{item.data.name}</h4>
                  <p>{this.getClients(item)}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      } else {
        return (
          <div className={this.state.multiple ? 'scroll withbutton' : 'scroll'}>
            {list.map(item => (
              <div className="list bare" key={item.id} onClick={() => this.clickElement(item)}>
                <div className={'icon '+Cal.getColor(item.data.time)}>
                  <p>{item.data.time}'</p>
                </div>
                <div className="main">
                  <h4>{item.data.name}</h4>
                  <p>{this.renderProgramInfo(item)}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
      
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box clients">
            <h2 className="mb-20 lft">{this.getTitle()}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            <div className="clear"></div>
            {this.renderSearch()}
            <div className="clear sv-20"></div>
            {this.renderContent()}
            {this.renderButton()}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalItems);