import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import HelperClients from '../../helper/Clients';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import InfoData from '../../components/Form/info';
import ModalClients from '../../components/Modals/clients';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalBookings from '../../components/Modals/bookings';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class EventPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      back: '/calendar',
      item: null,
      events: global.events,
      private: true,
      bookedState: 'button',
      activity: [],
      selected: [],
      invitees: '',
      showModalDelete: false,
      showModalClients: false,
      showModalBookings: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Event - PT Mate')
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('eventsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())

    var back = global.currentPage
    if(global.currentPage === '/clients' && global.currentClient !== '') {
      back = '/clients/'+global.currentClient+'/sessions'
    }

    this.setState({
      back: back
    })
    this.configureData()

    if(global.clientTokens.length === 0) {
      for(var client of global.clients) {
        if(client.data.uid !== '') {
          Connector.getClientTokenAll(client.data.uid, client.id)
        }
      }
    }
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      var id = arr[arr.length-1]
      var item = null
      var inv = ''
      var sel = []
      var priv = true
      for(var event of global.events) {
        if(event.id === id) {
          id = event.id
          item = event
          if(item.data.emails !== undefined && item.data.emails !== null) {
            inv = item.data.emails
          }
          if(item.data.clients !== undefined) {
            sel = item.data.clients
          }
          if(item.data.private !== undefined) {
            priv = item.data.private
          }
          if(item.data.bookable) {
            priv = false
          }
        }
      }

      this.setState({
        events: global.events,
        id: id,
        item: item,
        invitees: inv,
        selected: sel,
        private: priv,
      });
    }
  }


  getClient(id) {
    var client = null
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        client = item2
      }
    }
    return client
  }


  getClientName(id) {
    var label = lang.t('common:label.deletedclient')
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    return label
  }


  getGroupName(id) {
    var label = lang.t('common:label.deletedgroup')
    for(var item of global.clientGroups) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }


  getEventTime() {
    var label = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM - LT')
    var tdy = Moment().format('DD/MM/YYYY')
    var tmr = Moment().add(1, 'days').format('DD/MM/YYYY')
    var dt = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
    if(dt === tdy) {
      label = lang.t('calendar:label.today')+' '+Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    if(dt === tmr) {
      label = lang.t('calendar:label.tomorrow')+' '+Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    return label
  }



  // Bookable stuff ------------------------------------------------------------



  addBooked(event) {
    var tmp = []
    if(this.state.item.data.clients !== undefined) {
      tmp = this.state.item.data.clients
    }
    tmp.push(event.target.value)
    var name = this.state.item.data.title
    var token = ''
    var email  = ''
    for(var item of global.clients) {
      if(item.id === event.target.value) {
        if(item.data.pushToken !== undefined && item.data.pushToken !== '') {
          token = item.data.pushToken
          email = item.data.email
          Connector.sendPushNotification(item.data.pushToken, lang.t('messaging:push.bookedin.title'), global.userName+lang.t('messaging:push.bookedin.text')+name+' '+Cal.getPushDate(this.state.item.data.date), 'event', this.state.item.id)
        }
      }
    }
    Firebase.database().ref('/events/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.clientbookedin'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    this.setState({
      bookedState: 'button'
    })

    // Update schedule
    var found1 = false
    var found2 = false
    for(var sitem of global.schedule) {
      if(sitem.id === this.state.item.id+'-push' && token !== '') {
        found1 = true
        var tmp1 = []
        if(sitem.data.tokens !== undefined) {
          tmp1 = sitem.data.tokens
        }
        tmp1.push(token)
        Firebase.database().ref('/schedule/'+sitem.id).update({
          tokens: tmp1,
        })
      }
      if(sitem.id === this.state.item.id+'-email' && email !== '' && global.spaceEmailReminder && (item.data.emailReminder || item.data.emailReminder === undefined)) {
        found2 = true
        var tmp2 = []
        if(sitem.data.tokens !== undefined) {
          tmp2 = sitem.data.tokens
        }
        tmp2.push(email)
        Firebase.database().ref('/schedule/'+sitem.id).update({
          tokens: tmp2,
        })
      }
    }

    var scheduled = Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')
    if(token !== '' && !found1) {
      Firebase.database().ref('/schedule/'+this.state.item.id+'-push').update({
        title: lang.t('messaging:push.sessionreminder.title'),
        desc: lang.t('messaging:push.bookedin.text4')+name+" "+this.getSpecialDate(this.state.item.data.timestamp, scheduled)+".",
        type: "",
        timestamp: parseInt(Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [token],
        message: "push",
        uid: global.uid,
        iid: this.state.item.id
      })
    }
    if(email !== '' && !found2 && global.spaceEmailReminder && (item.data.emailReminder || item.data.emailReminder === undefined)) {
      Firebase.database().ref('/schedule/'+this.state.item.id+'-email').update({
        title: global.userBusiness,
        desc: name,
        type: this.getSpecialDate(this.state.item.data.timestamp, scheduled),
        timestamp: parseInt(Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')),
        tokens: [email],
        message: "sessionemail",
        uid: global.uid,
        iid: global.uid
      })
    }
    if(email !== '' && global.spaceEmailReminder && (item.data.emailReminder || item.data.emailReminder === undefined)) {
      var callFunction = Firebase.functions().httpsCallable('sendReminder');
      callFunction({type: 'booked', email: email, name: global.userBusiness, id: global.uid, session: name, time: Moment(this.state.item.data.timestamp, 'X').locale(lang.language).format('llll')}).then(function(result) {});
    }
  }


  getSpecialDate(date1, date2) {
    var label = Moment(date1, 'X').locale(lang.language).format('llll')
    if(Moment(date1, 'X').format('DD/MM/YYYY') === Moment(date2, 'X').format('DD/MM/YYYY')) {
      label = 'today '+Moment(date1, 'X').locale(lang.language).format('LT')
    }
    if(Moment(date1, 'X').format('DD/MM/YYYY') === Moment(date2, 'X').add(1, 'days').format('DD/MM/YYYY')) {
      label = 'tomorrow '+Moment(date1, 'X').locale(lang.language).format('LT')
    }
    return label
  }


  removeBooked(item) {
    var tmp = this.state.item.data.clients
    var tmp2 = []
    var id = ''
    var id2 = ''
    for(var i=0; i<tmp.length; i++) {
      if(tmp[i] === item) {
        id = tmp[i]
        tmp.splice(i, 1)
      }
    }
    if(this.state.item.data.waiting !== undefined) {
      tmp2 = this.state.item.data.waiting
      if(this.state.item.data.waiting.length > 0) {
        tmp.push(tmp2[0])
        id2 = tmp[0]
        tmp2.splice(0, 1)
      }
    }
    var tokens1 = []
    var tokens2 = []
    var token1 = ''
    var token2 = ''
    var email1 = ''
    var email2 = ''
    var name = this.state.item.data.title
    var text = lang.t('messaging:nobookedin.text')+name+' '+Cal.getPushDate(this.state.item.data.date)
    var text2 = lang.t('messaging:push.nowbookedin.text')+name+' '+Cal.getPushDate(this.state.item.data.date)
    for(var client of global.clients) {
      if(client.data.pushToken !== undefined && client.data.pushToken !== '') {
        if(client.id === id) {
          tokens1.push(client.data.pushToken)
          token1 = client.data.pushToken
          email1 = client.data.email
        }
        if(client.id === id2 && id2 !== '') {
          tokens2.push(client.data.pushToken)
          token2 = client.data.pushToken
          email2 = client.data.email
        }
      }
    }
    if(tokens1.length > 0) {
      Connector.sendPushNotification('', global.userName, text, 'event', this.state.item.id, tokens1)
    }
    if(tokens2.length > 0) {
      Connector.sendPushNotification('', global.userName, text2, 'event', this.state.item.id, tokens2)
    }
    Firebase.database().ref('/events/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp,
      waiting: tmp2
    })

    // Update schedule
    for(var sitem of global.schedule) {
      if(sitem.id === this.state.item.id+'-push') {
        var tmp1 = []
        if(sitem.data.tokens !== undefined) {
          tmp1 = sitem.data.tokens
        }
        if(tmp1.length > 0) {
          for(var i1=0; i1<tmp1.length; i1++) {
            if(tmp1[i1] === token1) {
              tmp1.splice(i1, 1)
            }
          }
          if(token2 !== '') {
            tmp1.push(token2)
          }
          Firebase.database().ref('/schedule/'+sitem.id).update({
            tokens: tmp1
          })
        }
      }
      if(sitem.id === this.state.item.id+'-email') {
        var tmp2 = []
        if(sitem.data.tokens !== undefined) {
          tmp2 = sitem.data.tokens
        }
        if(tmp2.length > 0) {
          for(var i2=0; i2<tmp2.length; i2++) {
            if(tmp2[i2] === email1) {
              tmp2.splice(i2, 1)
            }
          }
          if(email2 !== '') {
            tmp2.push(email2)
          }
          Firebase.database().ref('/schedule/'+sitem.id).update({
            tokens: tmp2
          })
        }
      }
    }

    EventEmitter.dispatch('showMessage', lang.t('messaging:message.clientremoved'));
  }


  removeWaiting(item) {
    var tmp = this.state.item.data.waiting
    var id = ''
    for(var i=0; i<tmp.length; i++) {
      if(tmp[i] === item) {
        tmp.splice(i, 1)
      }
    }
    var name = this.state.item.data.title
    var text = lang.t('messaging:push.nowaiting.text')+name+' '+Cal.getPushDate(this.state.item.data.date)
    for(var client of global.clients) {
      if(client.data.pushToken !== undefined && client.data.pushToken !== '') {
        if(client.id === id) {
          id = tmp[i]
          Connector.sendPushNotification(client.data.pushToken, global.userName, text, 'event', this.state.item.id)
        }
      }
    }
    Firebase.database().ref('/events/'+global.uid+'/'+this.state.item.id).update({
      waiting: tmp
    })
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.clientremoved'));
  }



  // Modals ------------------------------------------------------------



  showModalHistory() {
    var typeact = ['bookingevent', 'bookingeventcancelled']
    var tmp = []
    for(var item of global.activity) {
      if(typeact.indexOf(item.data.type) !== -1) {
        var arract = item.data.data.split(',')
        if(this.state.item.id === arract[1]) {
          var dateact = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
          var client = null
          var title = lang.t('modals:history.title')
          if(item.data.type === 'bookingeventcancelled') {
            title = lang.t('modals:history.title.canceled')
          }
          for(var cl1 of global.clients) {
            if(cl1.data.uid === arract[0]) {
              client = cl1
            }
          }
          if(client !== null) {
            tmp.push({
              date: dateact,
              title: title,
              client: client
            })
          }
          
        }
      }
    }
    this.setState({
      showModalBookings: true,
      activity: tmp
    })
  }


  hideModals() {
    this.setState({
      showModalDelete: false,
      showModalClients: false,
      showModalBookings: false,
    })
  }


  updateAttendance(items) {
    this.hideModals()
    Firebase.database().ref('/events/'+global.uid+'/'+this.state.item.id).update({
      clients: items,
      attendance: 3,
    }).then(() => {
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.attendanceupdated'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  deleteEvent() {
    this.hideModals()
    Firebase.database().ref('/events/'+global.uidUser+'/'+this.state.item.id).remove()
    global.message = lang.t('messaging:message.eventdeleted')
    this.props.history.push(this.state.back)
  }



  // Display stuff ------------------------------------------------------------



  renderButtonEdit() {
    if(HelperBusiness.getRoleIncl('')) {
      if(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm') > Moment()) {
        return (
          <div>
            <Link to={'/calendar/edit-event/'+this.state.item.id} className="btn primary">{lang.t('calendar:title.editevent')}</Link>
            <div className="sv-20"></div>
          </div>
        )
      }
    }
  }


  renderButtonAttendance() {
    if(HelperBusiness.getRoleIncl('admin,trainer') && Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm') < Moment() && this.state.item.data.bookable) {
      return (
        <div>
          <button className="btn primary" onClick={() => this.setState({showModalClients: true})}>{this.state.item.data.attendance === 3 ? lang.t('calendar:button.manageattendance') : lang.t('calendar:button.confirmattendance')}</button>
          <div className="sv-20"></div>
        </div>
      )
    }
  }


  renderButtonLink() {
    if( Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(-5, 'minutes') > Moment() && Moment() < Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(this.state.item.data.duration, 'minutes') && this.state.item.data.link !== '' && this.state.item.data.link !== undefined) {
      return (
        <div>
          <a className="btn secondary" href={this.state.item.data.link} target="_blank" rel="noopener noreferrer">{lang.t('calendar:button.openvirtuallink')}</a>
          <div className="sv-20"></div>
        </div>
      )
    }
  }
  

  renderButtons() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <div>
          {this.renderButtonEdit()}
          {this.renderButtonAttendance()}
          {this.renderButtonLink()}
          <button className="btn tertiary width-12 close" onClick={() => this.setState({showModalDelete: true})}>{lang.t('calendar:button.deleteevent')}</button>
        </div>
      )
    }
  }


  renderGroups() {
    if(this.state.item.data.groups !== undefined) {
      if(this.state.item.data.groups.length > 0) {
        return (
          <div className="mb-20">
            <label>{lang.t('calendar:label.accesslimited')}</label>
            {this.state.item.data.groups.map(item => (
              <div className="client small" key={item}>
                <div className="icon tertiary">
                  <div className="inner group"></div>
                </div>
                <p>{this.getGroupName(item)}</p>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderButtonHistory() {
    if(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm') > Moment().add(-2, 'days') && HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className="icontoggle history rgt" onClick={() => this.showModalHistory()}></div>
      )
    }
  }


  renderRemove(type, item, list) {
    var date = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-30, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(type !== 'past' && now < date && HelperBusiness.getRoleIncl('trainer')) {
      if(list === 'attendees') {
        return (
          <button className="btn tertiary rgt small close" style={{fontSize: 10, marginTop: 7}} onClick={() => this.removeBooked(item)}>{lang.t('common:button.remove')}</button>
        )
      } else {
        return (
          <button className="btn tertiary rgt small close" style={{fontSize: 10, marginTop: 7}} onClick={() => this.removeWaiting(item)}>{lang.t('common:button.remove')}</button>
        )
      }
    }
  }


  renderClients() {
    var type = 'future'
    var date = parseInt(Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(now > date) {
      type = 'past'
    }
    var label = 'No clients booked in yet'
    if(this.state.item.data.clients === undefined || this.state.item.data.clients.length === 0) {
      return (
        <p>{label}</p>
      )
    } else {
      return (
        <div>
        {this.state.item.data.clients.map(item => (
          <div className="client small" key={item}>
            {HelperClients.getImage(this.getClient(item))}
            <p><span className="lft">{this.getClientName(item)}</span></p>        
            {this.renderRemove(type, item, 'attendees')}
            <div className="clear"></div>
          </div>
        ))}
        </div>
      )
    }
  }


  renderWaitingList(type) {
    var label = lang.t('calendar:label.noclientsbooked')
    if(type === 'past') {
      label = lang.t('calendar:label.selectattendees')
    }
    if(this.state.item.data.clients === undefined || this.state.item.data.clients.length === 0) {
      return (
        <p>{label}</p>
      )
    } else {
      return (
        <div>
        {this.state.item.data.waiting.map(item => (
          <div className="client small" key={item}>
            {HelperClients.getImage(this.getClient(item))}
            <p><span className="lft">{this.getClientName(item)}</span></p>        
            {this.renderRemove(type, item, 'waiting')}
            <div className="clear"></div>
          </div>
        ))}
        </div>
      )
    }
  }


  renderWaiting() {
    var date = parseInt(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(-5, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(now < date && this.state.item.data.waiting !== undefined) {
      if(this.state.item.data.waiting.length !== 0) {
        return (
          <div className="info">
            <label>{lang.t('calendar:label.clientsinwaitinglist')}</label>
            {this.renderWaitingList('future')}
          </div>
        )
      }
    }
  }


  renderAddBooked() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      var date = parseInt(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(-5, 'minutes').format('X'))
      var now = parseInt(Moment().format('X'))
      if(this.state.item.data.clients !== undefined) {
        if(this.state.item.data.clients.length < this.state.item.data.max || this.state.item.data.max === 0) {
          if(this.state.bookedState === 'form') {
            var list = []
            for(var client of global.clients) {
              if(this.state.item.data.clients.indexOf(client.id) === -1) {
                list.push(client)
              }
            }
            list.sort((a,b) => a.data.name.localeCompare(b.data.name))
            return (
              <div className="mt-10 mb-20">
                <button className="btn tertiary small close width-12 mb-10" onClick={() => this.setState({bookedState: 'button'})}>{lang.t('common:button.cancel')}</button>
                <select onChange={event => this.addBooked(event)}>
                  <option>- {lang.t('calendar:form.selectclient')} -</option>
                  {list.map(item => (
                    <option value={item.id}>{item.data.name}</option>
                  ))}
                </select>
              </div>
            )
          } else {
            if(now > date) {
              // Nothing
            } else {
              return (
                <div className="mt-10 mb-20">
                  <button className="btn tertiary small add width-12" onClick={() => this.setState({bookedState: 'form'})}>{lang.t('calendar:button.bookinclient')}</button>
                </div>
              )
            }
            
          }
        }
      } else {
        if(this.state.bookedState === 'form') {
          var list2 = global.clients
          list2.sort((a,b) => a.data.name.localeCompare(b.data.name))
          return (
            <div className="mt-10 mb-20">
              <button className="btn tertiary small close width-12 mb-10" onClick={() => this.setState({bookedState: 'button'})}>{lang.t('common:button.cancel')}</button>
              <select onChange={event => this.addBooked(event)}>
                <option>- Select a client -</option>
                {list2.map(item => (
                  <option value={item.id}>{item.data.name}</option>
                ))}
              </select>
            </div>
          )
        } else {
          if(now > date) {
            // Nothing
          } else {
            return (
              <div className="mt-10 mb-20">
                <button className="btn tertiary small add width-12" onClick={() => this.setState({bookedState: 'form'})}>{lang.t('calendar:button.bookinclient')}</button>
              </div>
            )
          }
        }
      }
    }
  }


  renderBookable() {
    if(this.state.item.data.bookable) {
      var date2 = parseInt(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(-5, 'minutes').format('X'))
      var now = parseInt(Moment().format('X'))
      return (
        <div>
          {this.renderGroups()}
          <div className="info">
            <label className="lft">{now > date2 && this.state.item.data.attendance === 3 ? lang.t('calendar:label.attended') : lang.t('calendar:label.bookedin')} {this.state.item.data.max > 0 ? '('+this.state.item.data.max+' max)' : ''}</label>
            {this.renderButtonHistory()}
            <div className="clear"></div>
            {this.renderClients()}
            {this.renderWaiting()}
            {this.renderAddBooked()}
          </div>
        </div>
      )
    }
  }


  renderInvitees() {
    if(this.state.invitees !== '') {
      var arr = this.state.invitees.split(',')
      for(var itm in arr) {
        itm.replace(' ', '')
      }
      return (
        <div className="info">
          <label>{lang.t('calendar:label.invitees')}</label>
          {arr.map(item => (
            <p>{item}</p>
          ))}
        </div>
      )
    }
  }


  renderLink() {
    if(this.state.item.data.link !== '') {
      return <InfoData label={lang.t('calendar:label.virtuallink')} value={this.state.item.data.link} small={true}/>
    }
  }


  renderVirtual() {
    if(this.state.item.data.link !== undefined && this.state.item.data.link !== '') {
      return <span className="pill primary" style={{marginLeft: 5, position: 'relative', top: '-2px'}}>{lang.t('calendar:label.virtual')}</span>
    }
  }


  renderLocation() {
    if(this.state.item.data.locationName !== undefined) {
      return (this.state.item.data.locationName === '' ? '' : ' - '+this.state.item.data.locationName)
    }
  }


  renderPrivateInfo() {
    if(this.state.item.data.private) {
      return (
        <label>{lang.t('calendar:label.privateevent')}</label>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      if(this.state.item === null) {
        return (
          <div>
            <div className="content form-container"></div>
            <Header title='Event' link={this.state.back}/>
            <Navigation active='calendar' />
          </div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-container">
              <div className="box">
              {this.renderPrivateInfo()}
                <h4 style={{marginBottom: 5}}>{this.getEventTime()}{this.renderVirtual()}</h4>
                <h3 className="mb-30">{Cal.getDuration(this.state.item.data.duration)} {this.renderLocation()}</h3>
                {this.renderBookable()}
                {this.renderInvitees()}
                <InfoData label={this.state.item.data.bookable ? lang.t('calendar:label.description') : lang.t('calendar:label.notes')} value={this.state.item.data.desc === '' ? '-' : this.state.item.data.desc} small={true}/>
                {this.renderLink()}
                <div className="clear sv-20"></div>
                {this.renderButtons()}
              </div>
            </div>
            <Header title={this.state.item.data.title} link={this.state.back}/>
            <Navigation active='calendar' />
            <ModalMessageSimple type='deleteevent' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteEvent()}/>
            <ModalClients show={this.state.showModalClients} title={lang.t('modals:clients.title.selectclients')} button={lang.t('modals:clients.button.confirmattendance')} multiple={true} selected={this.state.selected} onHide={() => this.hideModals()} clickMainButton={(event) => this.updateAttendance(event)}/>
            <ModalBookings show={this.state.showModalBookings} activity={this.state.activity} onHide={() => this.hideModals()}/>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='calendar-locked2' type='simple'/>
          </div>
          <Header title={lang.t('calendar:title.event')} link='/calendar'/>
          <Navigation active='calendar' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common','modals','messaging'])(withRouter(withAuthorization(condition)(EventPage)));