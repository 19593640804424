import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Firebase from 'firebase';
import 'firebase/functions';
import Resizer from "react-image-file-resizer";
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import HelperEmptyImg from '../../helper/EmptyLarge';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListEmpty from '../../components/List/empty';
import InfoData from '../../components/Form/info';
import InputToggle from '../../components/Form/toggle';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import Textarea from '../../components/Form/textarea';
import Select from '../../components/Form/select';
import Loader from '../../components/Form/loader';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalCard from '../../components/Modals/card';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      margin: 0,
      log: global.userLog,
      loading: false,
      session: [global.userComments, global.userShowBooked, global.userAllowBooking, global.userReminder],
      limit: global.userLimitBooking,
      community: [global.userCommunity, global.userCommunityPost],
      valuesDetails: [global.userBusiness, "", "", "", global.spacePhone, global.spaceEmail, global.spacePin],
      valuesInvoice: ["", "", ""],
      errorDetails: [false, false, false, false],
      address: [],
      card: global.userCard,
      cards: global.cards,
      stripe: global.userStripeConnect,
      userStripe: global.userStripe,
      showModalDetails: false,
      hiddenDetails: 'hidden',
      theme: global.spaceTheme,
      file: null,
      fileDisplay: null,
      expand: '',
      h1: 66,
      h2: 66,
      h3: 66,
      h4: 66,
      billing: [global.userChargeSessions, global.spaceEmailPacks],
      noshow: 'session',
      fee: 0,
      rec: global.spaceAllowRecurring,
      email: global.spaceEmailReminder,
      showGST: false,
      gst: global.spaceGST,
      showModalMessage: false,
      showModalCard: false,
      showModalLocked: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Settings - PT Mate', ROUTES.ADMINSETTINGS)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('logLoaded', (event) => this.checkLog())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false
    })
  }


  configureData() { 
    if(this._isMounted) {
      var address = []
      if(global.spaceAddress !== '' && global.spaceAddress !== undefined) {
        address = global.spaceAddress.split('||')
      }
      if(address.length < 4) {
        for(var i=address.length; i<4; i++) {
          address.push('-')
        }
      }
      var show = false
      if(global.spaceGST > 0) {
        show = true
      }
      this.setState({
        stripe: global.userStripeConnect,
        userStripe: global.userStripe,
        session: [global.userComments, global.userShowBooked, global.userAllowBooking, global.userReminder],
        limit: global.userLimitBooking,
        community: [global.userCommunity, global.userCommunityPost],
        address: address,
        card: global.userCard,
        cards: global.cards,
        theme: global.spaceTheme,
        valuesDetails: [global.userBusiness, address[1], address[2], address[3], global.spacePhone, global.spaceEmail, global.spacePin],
        billing: [global.userChargeSessions, global.spaceEmailPacks],
        noshow: global.spaceNoshow,
        fee: global.spaceNoshowFee,
        rec: global.spaceAllowRecurring,
        email: global.spaceEmailReminder,
        showGST: show,
        gst: global.spaceGST,
      })
      this.checkLog()
    }
  }


  checkLog() {
    if(this._isMounted) {
      for(var item of global.userLog) {
        if(item.data.title === 'cardnewerror' || item.data.title === 'carddeleteerror') {
          EventEmitter.dispatch('showMessageError', item.data.message);
          Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
          this.setState({
            loading: false,
            card: global.userCard,
            cards: global.cards,
          })
        }
        if(item.data.title === 'cardnew' || item.data.title === 'carddelete') {
          EventEmitter.dispatch('showMessage', this.state.message);
          Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
          this.setState({
            loading: false,
            card: global.userCard,
            cards: global.cards,
          })
          Connector.loadUser((result) => {})
        }
      }
    }
    }


  getImage() {
    if(global.spaceImage === '') {
      var inits = ''
      let arr = global.userName.split(' ')
      if(arr.length > 1) {
        inits = arr[0].charAt(0)+arr[1].charAt(0)
      } else {
        inits = arr[0].charAt(0)
      }
      return (
        <div className="avatar business">
          <p>{inits}</p>
        </div>
      )
    } else {
      return (
        <div className="avatar business" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
      )
    }
  }


  getCommunityStatus() {
    var label = lang.t('admin:label.active1')
    if(!global.userCommunity) {
      label = lang.t('admin:label.deactivated')
    }
    if(global.userCommunity && !global.userCommunityPost) {
      label = lang.t('admin:label.active2')
    }
    return label
  }


  getSubscription(type) {
    var label = '-'
    if(type === 'name') {
      if(global.userSubPlan === 'foundmonthly' || global.userSubPlan === 'foundyearly') {
        label = lang.t('admin:label.subscribedtofoundation')
      }
      if(global.userSubPlan === 'basicmonthly' || global.userSubPlan === 'basicyearly') {
        label = lang.t('admin:label.subscribedtobasic')
      }
      if(global.userSubPlan === 'promonthly' || global.userSubPlan === 'proyearly') {
        label = lang.t('admin:label.subscribedtopro')
      }
      if(global.userSubPlan === 'businessmonthly' || global.userSubPlan === 'businessyearly') {
        label = lang.t('admin:label.subscribedtobusiness')
      }
      if(global.userSubStatus === 'trialing') {
        label = lang.t('admin:label.free14daytrial')
      }
    }
    if(type === 'date') {
      label = lang.t('admin:label.nextpayment')+': '+global.prices[4]+' '+lang.t('admin:label.on')+' '+Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMMM YYYY')
      if(global.userSubPlan === 'foundmonthly' || global.userSubPlan === 'foundyearly') {
        label = lang.t('admin:label.nextpayment')+': '+global.prices[0]+' '+lang.t('admin:label.on')+' '+Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMMM YYYY')
      }
      if(global.userSubPlan === 'basicmonthly' || global.userSubPlan === 'basicyearly') {
        label = lang.t('admin:label.nextpayment')+': '+global.prices[2]+' '+lang.t('admin:label.on')+' '+Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMMM YYYY')
      }
      if(global.userSubPlan === 'businessmonthly' || global.userSubPlan === 'businessyearly') {
        label = lang.t('admin:label.nextpayment')+': '+global.prices[4]+' '+lang.t('admin:label.on')+' '+Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMMM YYYY')
      }
      if(global.userSubPlan === 'spark') {
        label = lang.t('admin:label.nextpayment')+': -'
      }
      if(global.userSubStatus === 'trialing') {
        label = lang.t('admin:label.ends')+' '+Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMMM YYYY')
      }
    }
    if(type === 'card') {
      label = lang.t('admin:label.nopaymentmethod')
      if(global.cards.length > 0 && global.userCard !== '' && global.userCard !== undefined) {
        for(var item of global.cards) {
          if(item.id === global.userCard) {
            label = item.data.brand+' '+lang.t('admin:label.ending')+' '+item.data.last4
          }
        }
      }
    }
    return label
  }


  toggleView(view, input) {
    var type = ''
    var h1 = 66
    var h2 = 66
    var h3 = 66
    var h4 = 66
    if(this.state.expand !== view || input === 'input') {
      type = view
      if(type === 'branding') {
        h1 = this.el1.clientHeight+100
      } else if(type === 'sessions') {
        h2 = this.el2.clientHeight+100
      } else if(type === 'billing') {
        h3 = this.el3.clientHeight+100
      } else if(type === 'community') {
        h4 = this.el4.clientHeight+100
      }
    }
    this.setState({
      h1: h1,
      h2: h2,
      h3: h3,
      h4: h4,
      expand: type,
    })
  }



  // Session management ------------------------------------------------------------
  


  toggleSession(num) {
    var tmp = this.state.session
    tmp[num] = !tmp[num]
    this.setState({
      session: tmp
    })
  }


  onChangeReminder(event) {
    var tmp = this.state.session
    tmp[3] = event
    this.setState({session: tmp});
  }


  toggleEmail() {
    if(this.state.email) {
      this.setState({
        email: false,
      })
    } else {
      if(global.userSubPlan === 'businessmonthly' || global.userSubPlan === 'businessyearly' || global.userSubStatus === 'trialing') {
        this.setState({
          email: true
        })
      } else {
        this.setState({
          showModalLocked: true
        })
      }
    }
  }


  saveSession() {
    var reminder = 24
    if(this.state.session[3] !== '' && this.state.session[3] !== undefined) {
      reminder = parseInt(this.state.session[3])
    }
    if(global.uidUser !== '') {
      Firebase.database().ref('/spaces/'+global.uidUser).update({
        comments: this.state.session[0],
        showBooked: this.state.session[1],
        allowBooking: this.state.session[2],
        reminder: reminder,
        limitBooking: this.state.limit,
        allowRecurring: this.state.rec,
        emailReminder: this.state.email
      })
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.sessionsettingsupdated'));
    }
  }



  // Billing management ------------------------------------------------------------
  


  toggleBilling(num) {
    var tmp = this.state.billing
    tmp[num] = !tmp[num]
    this.setState({
      billing: tmp
    }, () => {
      if(num  !== 2) {
        this.toggleView('billing', 'input')
      }
    })
  }


  saveBilling() {
    if(global.uidUser !== '') {
      var gst = 0
      if(this.state.showGST && this.state.gst !== '' && this.state.gst !== 0) {
        gst = parseFloat(this.state.gst)
      }
      Firebase.database().ref('/spaces/'+global.uidUser).update({
        chargeSessions: this.state.billing[0],
        emailPacks: this.state.billing[1],
        noshow: this.state.noshow,
        noshowFee: parseFloat(this.state.fee),
        gst: gst
      })
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.billingsettingsupdated'));
    }
  }



  // Community management ------------------------------------------------------------
  


  toggleCommunity(num) {
    if(global.userSubStatus === 'trialing' || global.maxPlans === 99999) {
      var tmp = this.state.community
      tmp[num] = !tmp[num]
      this.setState({
        community: tmp
      }, () => {
        if(num  === 0) {
          this.toggleView('community', 'input')
        }
      })
    }
  }


  saveCommunity() {
    if(global.uidUser !== '') {
      Firebase.database().ref('/spaces/'+global.uidUser).update({
        community: this.state.community[0],
        communityPost: this.state.community[1]
      })
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.communitysettingsupdated'));
    }
  }


  // Business details ------------------------------------------------------------



  showModalDetails() {
    var tmp = ["", "", ""]
    if(global.spaceInvoice1 !== undefined && global.spaceInvoice1 !== '') {
      var arr = global.spaceInvoice1.split("\n")
      tmp[0] = arr[3]
      tmp[1] = arr[4]
    }
    if(global.spaceInvoice2 !== undefined) {
      tmp[2] = global.spaceInvoice2
    }
    this.setState({
      showModalDetails: true,
      errorDetails: [false, false, false, false],
      valuesInvoice: tmp
    })
    setTimeout(() => {
      this.setState({
        hiddenDetails: '',
      });
    }, 100);
  }


  hideModalDetails() {
    this.setState({
      hiddenDetails: 'hidden',
    })
    setTimeout(() => {
      this.setState({
        showModalDetails: false,
        file: null,
        fileDisplay: null,
      });
    }, 500);
  }


  renderModalDetails() {
    if(this.state.showModalDetails) {
      return (
        <div className={'overlay '+this.state.hiddenDetails}>
          <div className="box clients">
            <h2 className="lft mb-20">{lang.t('modals:business.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalDetails()}>{lang.t('common:button.close')}</button>
            <div className="scroll">
              <label>{lang.t('admin:label.logo')}</label>
              <div className="avatar-container">
                {this.displayImage()}
              </div>
              <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
              <div className="sv-20"></div>
              <InputText label={lang.t('modals:business.businessname')+"*"} value={this.state.valuesDetails[0]} error={this.state.errorDetails[0]} errormsg={lang.t('modals:business.error1')} onChange={(event) => this.onChangeDetails(event, 0)}/>
              <InputText label={lang.t('admin:label.address')} value={this.state.valuesDetails[1]} onChange={(event) => this.onChangeDetails(event, 1)}/>
              <InputText label={lang.t('modals:business.suburb')} value={this.state.valuesDetails[2]} onChange={(event) => this.onChangeDetails(event, 2)}/>
              <InputNumber label={lang.t('modals:business.postcode')} value={this.state.valuesDetails[3]} onChange={(event) => this.onChangeDetails(event, 3)}/>
              <InputNumber label={lang.t('admin:label.businessphone')+"*"} value={this.state.valuesDetails[4]} error={this.state.errorDetails[1]} errormsg={lang.t('modals:business.error2')} onChange={(event) => this.onChangeDetails(event, 4)}/>
              <InputText label={lang.t('admin:label.businessemailaddress')+"*"} value={this.state.valuesDetails[5]} error={this.state.errorDetails[2]} errormsg={lang.t('modals:business.error3')} onChange={(event) => this.onChangeDetails(event, 5)}/>
              <InputNumber label={lang.t('admin:label.memberappconnectionpin')+"*"} value={this.state.valuesDetails[6]} error={this.state.errorDetails[3]} errormsg={lang.t('modals:business.error4')} onChange={(event) => this.onChangeDetails(event, 6)}/>
              <h3 className="mt-20 mb-20">{lang.t('modals:business.invoices')}</h3>
              <p className="small mb-20">{lang.t('modals:business.invoices.text')}</p>
              <InputText label={global.userCountry === 'au' ? 'ABN' : 'EIN'} value={this.state.valuesInvoice[0]} onChange={(event) => this.onChangeInvoice(event, 0)}/>
              <InputText label={lang.t('modals:business.additionalinformation')} placeholder={lang.t('modals:business.additionalinformation.placeholder')} value={this.state.valuesInvoice[1]} onChange={(event) => this.onChangeInvoice(event, 1)}/>
              <Textarea label={lang.t('modals:business.footer')} value={this.state.valuesInvoice[2]} onChange={(event) => this.onChangeInvoice(event, 2)}/>
            </div>
            <button className="btn primary" onClick={() => this.saveDetails()}>{lang.t('common:button.savechanges')}</button>
          </div>
        </div>
      )
    }
  }


  displayImage() {
    if(this.state.file === null) {
      return (
        this.getImage()
      )
    } else {
      return (
        <div className="avatar business" style={{backgroundImage: 'url('+this.state.fileDisplay+')'}}> </div>
      )
    }
  }


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay:  URL.createObjectURL(file)
    })
  }


  onChangeDetails(event, num) {
    var tmp = this.state.valuesDetails
    tmp[num] = event
    this.setState({valuesDetails: tmp});
  };


  onChangeInvoice(event, num) {
    var tmp = this.state.valuesInvoice
    tmp[num] = event
    this.setState({valuesInvoice: tmp});
  };


  saveDetails() {
    var address = this.state.valuesDetails[0]+"||"+this.state.valuesDetails[1]+"||"+this.state.valuesDetails[2]+"||"+String(this.state.valuesDetails[3])
    if(this.state.valuesDetails[0]!== '' && this.state.valuesDetails[4] !== '' && this.state.valuesDetails[5] !== '' && this.state.valuesDetails[6] !== '') {
      if(global.uidUser !== '') {
        var invoice1 = this.state.valuesDetails[1]+'\n'+this.state.valuesDetails[2]+', '+this.state.valuesDetails[3]+'\n'+this.state.valuesDetails[4]+'\n'+this.state.valuesInvoice[0]+'\n'+this.state.valuesInvoice[1]
        this.saveImage()
        this.hideModalDetails()
        Firebase.database().ref('/spaces/'+global.uidUser).update({
          name: this.state.valuesDetails[0],
          address: address,
          pin: String(this.state.valuesDetails[6]),
          phone: String(this.state.valuesDetails[4]),
          email: String(this.state.valuesDetails[5]),
          invoice1: invoice1,
          invoice2: this.state.valuesInvoice[2]
        }).then(() => {
          EventEmitter.dispatch('showMessage', lang.t('messaging:message.businessdetailsupdated'));
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
        Firebase.database().ref('/public/'+global.uidUser).update({
          color: this.state.theme,
          name: this.state.valuesDetails[0],
          logo: global.spaceImage
        })
      }
    } else {
      var tmp = [false, false, false, false]
      if(this.state.valuesDetails[0]=== '') { tmp[0] = true }
      if(this.state.valuesDetails[4]=== '') { tmp[1] = true }
      if(this.state.valuesDetails[5]=== '') { tmp[2] = true }
      if(this.state.valuesDetails[6]=== '') { tmp[3] = true }
      this.setState({errorDetails: tmp})
    }
  }


  saveImage() {
    if(this.state.file !== null) {
      const sr = Firebase.storage().ref()
      const fr = sr.child('images/spaces/'+global.uidUser+'.jpg')
      Resizer.imageFileResizer(
        this.state.file,
        800,
        800,
        "JPEG",
        70,
        0,
        (uri) => {
          var ar = uri.split(',')
          if(global.uidUser !== '') {
            fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
              Firebase.database().ref('/spaces/'+global.uidUser).update({
                image: 'images/spaces/'+global.uidUser+'.jpg',
                imageDate: Moment().format('DD/MM/YYYY HH:mm')
              })
              Firebase.database().ref('/public/'+global.uidUser).update({
                logo: 'images/spaces/'+global.uidUser+'.jpg',
              })
            }).catch((error)=>{
              EventEmitter.dispatch('showMessageError', error.message);
            })
          }
        },
        "base64",
        200,
        200
      );
    }
  }



  // Theme settings ------------------------------------------------------------



  saveTheme() {
    if(global.maxTrainers === 0) {
      this.setState({
        showModalMessage: true,
      })
    } else {
      global.spaceTheme = this.state.theme
        if(global.uidUser !== '') {
        Firebase.database().ref('/spaces/'+global.uidUser).update({
          theme: this.state.theme,
        }).then(() => {
          EventEmitter.dispatch('showMessage', lang.t('messaging:message.brandthemeupdated'));
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
        Firebase.database().ref('/public/'+global.uidUser).update({
          color: this.state.theme,
          name: global.userBusiness,
          logo: global.spaceImage
        })
      }
    }
  }



  // Demo data ------------------------------------------------------------



  deleteDemoData() {
    Firebase.database().ref('/recurring/'+global.uid+"/demo").remove()
    Firebase.database().ref('/sessions/'+global.uid+"/demo1").remove()
    Firebase.database().ref('/sessions/'+global.uid+"/demo2").remove()
    Firebase.database().ref('/sessions/'+global.uid+"/demo3").remove()
    Firebase.database().ref('/sessions/'+global.uid+"/demo4").remove()
    Firebase.database().ref('/sessions/'+global.uid+"/demo5").remove()
    Firebase.database().ref('/sessions/'+global.uid+"/demo6").remove()
    Firebase.database().ref('/sessions/'+global.uid+"/demo7").remove()
    Firebase.database().ref('/payments/'+global.uid+"/demo1").remove()
    Firebase.database().ref('/payments/'+global.uid+"/demo2").remove()
    Firebase.database().ref('/payments/'+global.uid+"/demo3").remove()
    Firebase.database().ref('/payments/'+global.uid+"/demo4").remove()
    Firebase.database().ref('/packs/'+global.uid+"/demo1").remove()
    Firebase.database().ref('/packs/'+global.uid+"/demo2").remove()
    Firebase.database().ref('/packs/'+global.uid+"/demo3").remove()
    Firebase.database().ref('/packs/'+global.uid+"/demo4").remove()
    Firebase.database().ref('/habits/'+global.uid+"/demo").remove()
    Firebase.database().ref('/invoices/'+global.uid+"/demo").remove()
    Firebase.database().ref('/workouts/'+global.uid+"/demo").remove()
    Firebase.database().ref('/forms/'+global.uid+"/demo1").remove()
    Firebase.database().ref('/forms/'+global.uid+"/demo2").remove()
    Firebase.database().ref('/activity/'+global.uid+"/demo1").remove()
    Firebase.database().ref('/activity/'+global.uid+"/demo2").remove()
    Firebase.database().ref('/activity/'+global.uid+"/demo3").remove()
    Firebase.database().ref('/clients/'+global.uid+"/demo1").remove()
    Firebase.database().ref('/clients/'+global.uid+"/demo2").remove()
    for(var item of global.sessions) {
      if(item.data.template === 'demo') {
        Firebase.database().ref('/clients/'+global.uid+"/"+item.id).remove()
      }
    }
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.demodatadeleted'));
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalMessage: false,
      showModalCard: false,
      showModalLocked: false,
    })
  }


  showModalCardEmail() {
    this.hideModals()
    setTimeout(() => {
      this.setState({showModalCard: true})
    }, 500);
  }


  createCard(values) {
    this.hideModals()
    this.setState({
      loading: true,
      message: lang.t('messaging:message.cardadded')
    })
    var current = ''
    if(this.state.card !== undefined && this.state.card !== '') {
      current = this.state.card
    }
    var callFunction = Firebase.functions().httpsCallable('replaceCard');
    callFunction({customer: global.userStripe, name: values[0], number: values[1], month: values[2], year: values[3], cvc: values[4], current: current}).then(function(result) {
      //var sanitizedMessage = result.data.text;
    });
  }


  deleteCard(card) {
    this.hideModals()
    this.setState({
      loading: true,
      message: lang.t('messaging:message.carddeleted'),
    })
    var callFunction = Firebase.functions().httpsCallable('deleteCard');
      callFunction({card: card, customer: global.userStripe, primary: 'remove'}).then(function(result) {
       //var sanitizedMessage = result.data.text;
    });
  }



  // Display stuff ------------------------------------------------------------



  renderAddons() {
    if(global.userSubdomain !== '') {
      return (
        <div className="infobox mb-20">
          <h5>{lang.t('admin:label.subscriptionaddons')}</h5>
          <p>{lang.t('admin:label.subscriptionaddons.text')} ({global.curSym}30/year):<br/>{global.userSubdomain}</p>
        </div>
      )
    }
  }


  renderShowStaffLink() {
    var allowed = true
    if(global.userSubPlan !== 'businessmonthly' && global.userSubPlan !== 'businessyearly' && global.userSubStatus !== 'trialing' && this.state.role > 0) {
      allowed = false
    }
    if(allowed) {
      return (
        <p className="small">{lang.t('admin:label.directlink2')}:<br/>ptmate.app/staff/{global.uid}/signup/0</p>
      )
    }
  }
  


  renderBranding() {
    return (
      <div className={this.state.expand === 'branding' ? 'accordion expanded box highlight clear mb-20' : 'accordion box highlight clear mb-20'} style={{height: this.state.h1}}>
        <h3 className="handle mb-30" onClick={() => this.toggleView('branding', 'main')}>{lang.t('admin:label.branding')}</h3>
        <div ref={(el1) => {this.el1 = el1}}>
          <div className="clear form-item">
            <label>{lang.t('admin:form.yourbrandcolor')}</label>
            <div className="radios">
              <div className={this.state.theme === 'blue' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'blue'})}>
                <div className="color theme-blue"></div>
              </div>
              <div className={this.state.theme === 'darkblue' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'darkblue'})}>
                <div className="color theme-darkblue"></div>
              </div>
              <div className={this.state.theme === 'vividblue' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'vividblue'})}>
                <div className="color theme-vividblue"></div>
              </div>
              <div className={this.state.theme === 'green' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'green'})}>
                <div className="color theme-green"></div>
              </div>
              <div className={this.state.theme === 'darkgreen' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'darkgreen'})}>
                <div className="color theme-darkgreen"></div>
              </div>
              <div className={this.state.theme === 'vividgreen' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'vividgreen'})}>
                <div className="color theme-vividgreen"></div>
              </div>
              <div className={this.state.theme === 'yellow' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'yellow'})}>
                <div className="color theme-yellow"></div>
              </div>
              <div className={this.state.theme === 'orange' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'orange'})}>
                <div className="color theme-orange"></div>
              </div>
              <div className={this.state.theme === 'red' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'red'})}>
                <div className="color theme-red"></div>
              </div>
              <div className={this.state.theme === 'pink' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'pink'})}>
                <div className="color theme-pink"></div>
              </div>
              <div className={this.state.theme === 'purple' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'purple'})}>
                <div className="color theme-purple"></div>
              </div>
              <div className={this.state.theme === 'brown' ? 'radio theme active col-1' : 'radio theme col-1'} onClick={() => this.setState({theme: 'brown'})}>
                <div className="color theme-brown"></div>
              </div>
            </div>
            <div className="sv-10"></div>
            <p className="small mb-30">{lang.t('admin:form.yourbrandcolor.info')}</p>
            <button className="btn primary" onClick={() => this.saveTheme()}>{lang.t('admin:button.savetheme')}</button>
          </div>
        </div>
      </div>
    )
  }


  renderSessionLimit() {
    if(global.userStripeConnect !== '' && global.userStripeConnect !== undefined) {
      return <InputToggle label={lang.t('admin:form.availablesessions')} value={!this.state.limit} onChange={() => this.setState({ limit: !this.state.limit})} text={lang.t('admin:form.availablesessions.text')}/>
    }
  }


  renderSessions() {
    return (
      <div className={this.state.expand === 'sessions' ? 'accordion expanded box highlight clear mb-20' : 'accordion box highlight clear mb-20'} style={{height: this.state.h2}}>
        <h3 className="handle mb-30" onClick={() => this.toggleView('sessions', 'main')}>{lang.t('admin:label.sessions')}</h3>
        <div ref={(el2) => {this.el2 = el2}}>
          <InputToggle label={lang.t('admin:form.clientfeedback')} value={this.state.session[0]} onChange={() => this.toggleSession(0)} text={lang.t('admin:form.clientfeedback.text')}/>
          <InputToggle label={lang.t('admin:form.groupsessionbookings')} value={this.state.session[1]} onChange={() => this.toggleSession(1)} text={lang.t('admin:form.groupsessionbookings.text')}/>
          <InputToggle label={lang.t('admin:form.lockin')} value={this.state.session[2]} onChange={() => this.toggleSession(2)} text={lang.t('admin:form.lockin.text')}/>
          {this.renderSessionLimit()}
          <InputToggle label={lang.t('admin:form.recurring')} value={this.state.rec} onChange={() => this.setState({rec: !this.state.rec})} text={lang.t('admin:form.recurring.text')}/>
          <InputNumber label={lang.t('admin:form.recurring.label')} value={this.state.session[3]} onChange={(event) => this.onChangeReminder(event)}/>
          <InputToggle label={lang.t('admin:form.emailconfirmation')} value={this.state.email} onChange={() => this.toggleEmail()} text={lang.t('admin:form.emailconfirmation.text')}/>
          <div className="clear sv-20"></div>
          <button className="btn primary" onClick={() => this.saveSession()}>Save changes</button>
        </div>
      </div>
    )
  }


  renderVerified() {
    if(global.userStripeConnect !== '' && global.userStripeConnect !== undefined && global.userVerified) {
      return (
        <div className="infobox green">
          <h5>{lang.t('admin:label.stripepaymentsenabled')}</h5>
          <p>{lang.t('admin:label.stripepaymentsenabled.text')}</p>
        </div>
      )
    } else {
      return (
        <div className="infobox yellow">
          <h5>{lang.t('admin:label.stripepaymentsnotenabled')}</h5>
          <p>{lang.t('admin:label.stripepaymentsnotenabled')}<br/><Link to={ROUTES.ACCOUNTBILLING} className="btn tertiary lft small">{lang.t('admin:button.enablestripepayments')}</Link><div className="clear"></div></p>
        </div>
      )
    }
  }


  renderNoshowFee() {
    if(this.state.noshow !== 'session') {
      return <InputNumber label={lang.t('admin:form.noshows.label')+' ('+global.curSym+')'} placeholder={lang.t('admin:form.noshows.placeholder')+' '+global.curSym} value={this.state.fee} info={lang.t('admin:form.noshows.info')} onChange={(event) => this.setState({fee: event})}/>
    }
  }


  renderNoshow() {
    if(this.state.billing[0]) {
      return (
        <div className="clear">
          <Select label={lang.t('admin:form.noshows')} value={this.state.noshow} values={[{name: lang.t('admin:form.noshows.label1'), value: 'session'}, {name: lang.t('admin:form.noshows.label2'), value: 'fee'}, {name: lang.t('admin:form.noshows.label3'), value: 'both'}, {name: lang.t('admin:form.noshows.label4'), value: 'none'}]} onChange={(event) => this.setState({noshow: event})}/>
          {this.renderNoshowFee()}
        </div>
      )
    }
  }


  renderGST() {
    if(this.state.showGST) {
      return (
        <div className="clear">
          <InputNumber label={lang.t('admin:form.gstincluded')} placeholder={lang.t('admin:form.gstincluded.placeholder')} value={this.state.gst} onChange={(event) => this.setState({gst: event})}/>
        </div>
      )
    }
  }


  renderBilling() {
    return (
      <div className={this.state.expand === 'billing' ? 'accordion expanded box highlight clear mb-20' : 'accordion box highlight clear mb-20'} style={{height: this.state.h3}}>
        <h3 className="handle mb-30" onClick={() => this.toggleView('billing', 'main')}>{lang.t('admin:label.billing')}</h3>
        <div ref={(el3) => {this.el3 = el3}}>
          <InputToggle label={lang.t('admin:form.chargesessions')} value={this.state.billing[0]} onChange={() => this.toggleBilling(0)} text={lang.t('admin:form.chargesessions.text')}/>
          <InputToggle label={lang.t('admin:form.emailpacks')} value={this.state.billing[1]} onChange={() => this.toggleBilling(1)} text={lang.t('admin:form.emailpacks.text')}/>
          {this.renderNoshow()}
          <InputToggle label={lang.t('admin:form.includegst')} value={this.state.showGST} onChange={() => this.setState({showGST: !this.state.showGST})} text={lang.t('admin:form.includegst.text')}/>
          {this.renderGST()}
          <div className="clear sv-10"></div>
          <button className="btn primary" onClick={() => this.saveBilling()}>{lang.t('common:button.savechanges')}</button>
          <div className="clear sv-30"></div>
          {this.renderVerified()}
        </div>
      </div>
    )
  }


  renderCommunityClients() {
    if(this.state.community[0]) {
      return <InputToggle label={lang.t('admin:form.clientposts')} value={this.state.community[1]} onChange={() => this.toggleCommunity(1)} text={lang.t('admin:form.clientposts.text')}/>
    }
  }


  renderCommunity() {
    return (
      <div className={this.state.expand === 'community' ? 'accordion expanded box highlight clear mb-20' : 'accordion box highlight clear mb-20'} style={{height: this.state.h4}}>
        <h3 className="handle mb-30" onClick={() => this.toggleView('community', 'main')}>{lang.t('admin:label.community')}</h3>
        <div ref={(el4) => {this.el4 = el4}}>
          <InputToggle label={lang.t('admin:form.communitypage')} value={this.state.community[0]} onChange={() => this.toggleCommunity(0)} text={lang.t('admin:form.communitypage.text')}/>
          {this.renderCommunityClients()}
          <div className="clear sv-20"></div>
          <button className="btn primary" onClick={() => this.saveCommunity()}>{lang.t('common:button.savechanges')}</button>
        </div>
      </div>
    )
  }


  renderDemo() {
    var demo = false
    for(var item of global.clients) {
      if(item.id === 'demo1' || item.id === 'demo2') {
        demo = true
      }
    }
    if(demo) {
      return <li><span onClick={() => this.deleteDemoData()}>{lang.t('admin:button.deletedemodata')}</span></li>
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <div className={'theme-'+this.state.theme}>
          <div className="content form-sidebar withtabs">
            <div className="col-6 mt-20">
              <div className="box mb-20">
                <h3 className="mb-20">{lang.t('admin:label.businessdetails')}</h3>
                <label>{lang.t('admin:label.logo')}</label>
                <div className="avatar-container clear">
                  {this.getImage()}
                </div>
                <div className="sv-10"></div>
                <InfoData small={true} label={lang.t('admin:label.address')} value={global.userBusiness+'\n'+(this.state.address.length > 1 ? this.state.address[1] : '-')+'\n'+(this.state.address.length > 2 ? this.state.address[2] : '-')+', '+(this.state.address.length > 3 ? this.state.address[3] : '-')+' ('+(global.userCountry === 'au' ? lang.t('admin:label.australia') : lang.t('admin:label.unitedstates'))+')'}/>
                <InfoData small={true} label={lang.t('admin:label.businessphone')} value={global.spacePhone}/>
                <InfoData small={true} label={lang.t('admin:label.businessemailaddress')} value={global.spaceEmail}/>
                <InfoData small={true} label={lang.t('admin:label.memberappconnectionpin')} value={global.spacePin}/>
                <button className="btn tertiary edit width-12" onClick={() => this.showModalDetails()}>{lang.t('common:button.update')}</button>
              </div>
              {this.renderBranding()}
              {this.renderSessions()}
              {this.renderBilling()}
              {this.renderCommunity()}
            </div>

            <div className="col-6 mt-20">
              <div className="sidebar">
                <h2 className="mb-20">Business settings</h2>
                <div className="empty large clear">
                  <div className="illustration mb-30">
                    <div className="img1"></div>
                    <div className="img2"></div>
                    <div className="img3"></div>
                    <div className="img4"></div>
                    {HelperEmptyImg.renderImage('settings')}
                  </div>
                </div>
                <p className="mb-30">{lang.t('admin:label.forminfo')}</p>
                <div className="infobox mb-20">
                  <h5>{lang.t('admin:label.yourptmatesubscription')}</h5>
                  <p>&nbsp;<br/>{this.getSubscription('name')}</p>
                  <p>{this.getSubscription('date')}</p>
                  <p>{this.getSubscription('card')}<br/>&nbsp;</p>
                  <p><Link to={ROUTES.SUBSCRIPTION} className="btn tertiary small edit" style={{textDecoration: 'none'}}>{lang.t('common:button.manage')}</Link></p>
                </div>
                {this.renderAddons()}
                <p className="small">{lang.t('admin:label.directlink1')}:<br/>ptmate.me/{global.uid}/signup<br/><Link to={'/clients/qrcode'} className="btn tertiary small" style={{float:'left'}}>{lang.t('admin:button.viewyourqrcode')}</Link></p>
                <div className="clear sv-30"></div>
                {this.renderShowStaffLink()}
                
                <div className="sv-40 clear"></div>
                <h3 className="mb-10">{lang.t('header:title.links')}</h3>
                <ul className="links">
                  <li><Link to={ROUTES.SUBSCRIPTION}>{lang.t('admin:link.manageyoursubscription')}</Link></li>
                  <li onClick={() => this.setState({showModalCard: true})} style={{cursor: 'pointer'}}>{lang.t('admin:link.updateyourpaymentmethod')}</li>
                  <li><Link to={ROUTES.ACCOUNTBILLING}>{lang.t('admin:link.managestripepayments')}</Link></li>
                  <li><a href="https://help.ptmate.net" target="_blank" rel="noopener noreferrer">{lang.t('admin:link.helpcenter')}</a></li>
                  {this.renderDemo()}
                </ul>
              </div>
            </div>
            <div className="sv-40 clear"></div>
          </div>
          <Header type='admin' active='settings'/>
          <Navigation active='admin' />
          {this.renderModalDetails()}
          <ModalMessageSimple type='branding' show={this.state.showModalMessage} onHide={() => this.hideModals()} clickMainButton={() => this.props.history.push(ROUTES.SUBSCRIPTION)}/>
          <ModalCard show={this.state.showModalCard} card={this.state.card} cards={this.state.cards} onHide={() => this.hideModals()} deleteCard={(event) => this.deleteCard(event)} clickMainButton={(event) => this.createCard(event)}/>
          <ModalMessageSimple type='emailsettingslocked' show={this.state.showModalLocked} onHide={() => this.hideModals()} clickMainButton={() => this.props.history.push(ROUTES.SUBSCRIPTION)}/>
          <Loader show={this.state.loading}/>
         </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='settings-locked' type='simple'/>
          </div>
          <Header type='simple' title={lang.t('nav:main.admin')}/>
          <Navigation active='admin' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['admin','common','header','nav','modals','messaging'])(withRouter(withAuthorization(condition)(SettingsPage)));