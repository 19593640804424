import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Firebase from 'firebase';
import 'firebase/functions';
import HelperBilling from '../../helper/Billing';
import HelperBusiness from '../../helper/Business';
import Cal from '../../helper/Calendar';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListEmptySearch from '../../components/List/empty-search';
import ListAvatar from '../../components/List/avatar';
import ModalBilling from '../../components/Modals/billing';
import ModalPayment from '../../components/Modals/payment';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class BillingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      clientsList: global.clients,
      clientsInactive: global.clientsInactive,
      payments: global.payments,
      paymentsList: global.payments,
      products: global.products,
      groups: global.clientGroups,
      dateRange: global.dateRange,
      search: '',
      currentGroup: '',
      currentPayment: null,
      stripe: global.userStripeConnect,
      bank: global.userStripeBank,
      show: 'payments',
      sessions: [],
      sessionsList: [],
      showModalPayment: false,
      showModalInfo: false,
      showModalLocked: false,
      showModalConfirm: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Payments - PT Mate', ROUTES.BILLING)
    HelperPage.checkMessage()
    this._isMounted = true
    
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.getClients())
    EventEmitter.subscribe('groupsLoaded', (event) => this.getClients())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('paymentsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('logLoaded', (event) => this.configureLog())
    EventEmitter.subscribe('locationSet', (event) => this.getClients())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getClients() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      var list = []
      if(global.loc === '') {
        for(var cl of global.clients) {
          list.push(cl.id)
        }
        for(var cl2 of global.clientsInactive) {
          list.push(cl2.id)
        }
      } else {
        for(var loc of global.locations) {
          if(loc.id === global.loc && loc.data.clients !== undefined) {
            list = loc.data.clients
          }
        }
      }
      for(var item1 of global.clients) {
        if(list.indexOf(item1.id) !== -1) {
          tmp1.push(item1)
        }
      }
      for(var item2 of global.clientsInactive) {
        if(list.indexOf(item2.id) !== -1) {
          tmp2.push(item2)
        }
      }
      this.setState({
        clients: tmp1,
        clientsInactive: tmp2,
        groups: global.clientGroups,
      }, () => {
        this.configureData()
        this.getClientsNum()
      });
    }
  }


  configureData() {
    if(this._isMounted) {
      var minDate = Cal.getDateRange()
      var list = []
      if(global.loc === '') {
        for(var cl of global.clients) {
          list.push(cl.id)
        }
        for(var cl2 of global.clientsInactive) {
          list.push(cl2.id)
        }
      } else {
        for(var loc of global.locations) {
          if(loc.id === global.loc && loc.data.clients !== undefined) {
            list = loc.data.clients
          }
        }
      }

      var tmp = []
      for(var item of global.payments) {
        var dt = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(item.data.timestamp !== undefined) {
          dt = Moment(item.data.timestamp, 'X').format('X')
        }
        if(dt > minDate && list.indexOf(item.data.client) !== -1) {
          tmp.push(item)
        }
      }

      var tmp3 = []
      for(var cl of this.state.clients) {
        var client = cl
        var family = false

        if(cl.data.parent !== undefined && cl.data.parent !== '') {
          for(var cl2 of this.state.clients) {
            if(cl2.id === cl.data.parent) {
              client = cl2
            }
          }
        } else {
          var name = cl.data.name
          if(cl.data.linked !== undefined) {
            name = cl.data.name+' ('+lang.t('billing:label.family')+')'
          }
          tmp3.push({id: cl.id,
            data: {
              name: name,
              account: '',
              linked: cl.data.linked,
              image: cl.data.image,
            },
            credits: cl.credits,
            creditse: cl.creditse,
            subscriptions: cl.subscriptions
          })
        }
        if(cl.data.linked !== undefined) {
          if(cl.data.linked.length > 0) {
            family = true
            tmp3.push({id: cl.id,
              data: {
                name: cl.data.name+' ('+cl.data.name+')',
                account: cl.id,
                linked: cl.data.linked,
                image: cl.data.image,
              },
              credits: cl.credits,
              creditse: cl.creditse,
              subscriptions: cl.subscriptions
            })
            for(var li of cl.data.linked) {
              var lname = cl.data.name
              for(var cli of global.clients) {
                if(cli.id === li) {
                  lname = cl.data.name+' ('+cli.data.name+')'
                }
              }
              tmp3.push({id: cl.id,
                data: {
                  name: lname,
                  account: li,
                  linked: cl.data.linked,
                  image: cl.data.image,
                },
                credits: cl.credits,
                creditse: cl.creditse,
                subscriptions: cl.subscriptions
              })
            }
          }
        }
      }

      this.configureList(tmp)

      this.setState({
        payments: tmp,
        products: global.products,
        stripe: global.userStripeConnect,
        bank: global.userStripeBank,
        sessions: tmp3,
        sessionsList: tmp3,
      })
    }
  }


  configureList(payments) {
    var tmp = []
    var tmp2 = []
    var tmp4 = []
    var tmp4a = []
    for(var item of payments) {
      var name = this.getClient(item.data.client)
      if(this.state.search === '' || (name.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && this.state.search !== '')) {
        tmp.push(item)
      }
    }

    for(var cl1 of this.state.sessions) {
      if(this.state.search === '' || (cl1.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && this.state.search !== '')) {
        tmp4a.push(cl1)
      }
    }

    if(this.state.currentGroup !== '') {
      var clients = []
      for(var gr of this.state.groups) {
        if(gr.id === this.state.currentGroup) {
          clients = gr.data.clients
        }
      }
      for(var tc of tmp) {
        for(var cc of clients) {
          if(cc === tc.data.client) {
            tmp2.push(tc)
          }
        }
      }
      for(var tc2 of tmp4a) {
        for(var cc2 of clients) {
          if(cc2 === tc2.id) {
            tmp4.push(tc2)
          }
        }
      }
    } else {
      tmp2 = tmp
      tmp4 = tmp4a
    }
    var tmp3 = global.clients
    this.setState({
      paymentsList: tmp2,
      clientsList: tmp3,
      sessionsList: tmp4,
    })
  }


  configureLog() {
    if(this._isMounted) {
      for(var item of global.userLog) {
        if(item.data.type === "chargessafe") {
          EventEmitter.dispatch('showMessage', lang.t('messaging:message.paymentsupdated'));
          Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
        }
      }
    }
  }


  getClient(id) {
    var item = null
    for(var client of this.state.clients) {
      if(client.id === id) {
        item = client
      }
    }
    for(var inact of global.clientsInactive) {
      if(inact.id === id) {
        item = client
      }
    }
    return item
  }


  getClientName(id) {
    var name = lang.t('common:label.deletedclient')
    for(var client of this.state.clients) {
      if(client.id === id) {
        name = client.data.name
      }
    }
    for(var inact of this.state.clientsInactive) {
      if(inact.id === id) {
        name = client.data.name
      }
    }
    return name
  }


  getClientsNum() {
    var arr = []
    for(var item of this.state.payments) {
      if(arr.indexOf(item.data.client) === -1) {
        arr.push(item.data.client)
      }
    }
    return arr.length
  }


  getClientsDiff() {
    var count = 0
    var minDate = Cal.getDateRangeDiff()[0]
    var prevDate = Cal.getDateRangeDiff()[1]
    var cls = []
    for(var item of global.payments) {
      var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(date > prevDate && date < minDate && cls.indexOf(item.data.client) === -1) {
        count++
        cls.push(item.data.client)
      }
    }
    return count
  }


  getSessions(item, group) {
    var label = lang.t('billing:label.none')
    var style = 'class'
    var total = 0
    var family = false
    var type = '1:1'
    if(group) {
      type = lang.t('billing:label.class')
    }
    if(item.data.linked !== undefined) {
      if(item.data.linked.length > 0) {
        family = true
      }
    }
    for(var cr1 of item.credits) {
      if(cr1.data.group === group && (!family || (family && cr1.data.account === item.data.account))) {
        total += cr1.data.sessionsPaid-cr1.data.sessionsTotal
      }
    }
    for(var cr2 of item.creditse) {
      if(cr2.data.group === group && (!family || (family && cr2.data.account === item.data.account)) && Moment(cr2.data.expires, 'X') > Moment()) {
        total += cr2.data.sessionsPaid-cr2.data.sessionsTotal
      }
    }
    total += HelperBilling.getClientSessionDebit(item, group, item.data.account, true, [])
    if(total < 0) {
      label = -total+' '+lang.t('billing:label.unpaid-s')
      style = 'red'
    }
    if(total === 1) {
      label = total+' '+lang.t('billing:label.available-s')
      style = 'yellow'
    }
    if(total > 1) {
      label = total+' '+lang.t('billing:label.available-s')
      style = 'green'
    }
    return <span>{type} <span className={'pill '+style}>{label}</span></span>
  }



  // Interactive stuff ------------------------------------------------------------



  setDateRange() {
    this.setState({
        dateRange: global.dateRange
    }, () => {
        this.configureData()
    });
  }


  setGroup(event) {
    this.setState({
      currentGroup: event.target.value
    }, then => {
      this.configureList(this.state.payments)
    })
  }


  filterPayments = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureList(this.state.payments)
    });
  }


  getStripePayments() {
    if(global.userStripeConnect !== undefined && global.userStripeConnect !== '') {
      var callFunction = Firebase.functions().httpsCallable('failsafe');
      callFunction({account: global.userStripeConnect}).then(function(result) {
        //var sanitizedMessage = result.data.text;
      });
    }
  }


  renderPaymentName(item) {
    var label = item.data.name
    if(item.data.subscription !== undefined && item.data.subscription !== '' && this.state.item !== null) {
      for(var sub of this.state.products) {
        if(sub.id === item.data.subscription) {
          label = sub.data.name
        }
      }
    }
    if(item.data.name.indexOf('Invoice ') !== -1 || item.data.name.indexOf('Subscription ') !== -1) {
      label = lang.t('billing:label.membership')
    }
    return label
  }


  renderPaymentDate(item) {
    var label = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')
    if(item.data.timestamp !== undefined) {
      label = Moment(item.data.timestamp, 'X').locale(lang.language).format('D MMM YYYY')
    }
    return label
  }



  // Modals ------------------------------------------------------------



  showModalPayment(item) {
    this.setState({
      showModalPayment: true,
      currentPayment: item,
    })
  }


  hideModals() {
    this.setState({
      showModalInfo: false,
      showModalLocked: false,
      showModalPayment: false,
      showModalConfirm: false,
    })
  }


  confirmRefundPayment() {
    setTimeout(() => {
      this.hideModals()
    }, 400);
    setTimeout(() => {
      this.setState({
        showModalConfirm: true
      });
    }, 550);
  }


  refundPayment() {
    this.hideModals()
    var callFunction = Firebase.functions().httpsCallable('refundPaymentV2');
    callFunction({uid: global.uid, charge: this.state.currentPayment.id, account: global.userStripeConnect, client: this.state.currentPayment.data.client, amount: this.state.currentPayment.data.amount, date: Moment().format('DD/MM/YYYY HH:mm')}).then(function(result) {});
  }



  // Display stuff ------------------------------------------------------------



  renderSessions() {
    if(this.state.show === 'sessions') {
      if(this.state.sessionsList.length > 0) {
        var list = this.state.sessionsList
        list.sort((a, b) => a.data.name.localeCompare(b.data.name))
        return (
          <div>
            {list.map(item => (
              <ListAvatar 
                key={item.id}
                client={item}
                link={'/billing/payment/'+item.id+'/group'}
                button={lang.t('billing:button.makepayment')}
                columns={[{text: item.data.name, width: 6}, {text: this.getSessions(item, false), width: 2}, {text: this.getSessions(item, true), width: 2}]}
              />
            ))}
          </div>
        )
      } else {
        return (
          <ListEmptySearch id='clients'/>
        )
      }
    }
  }


  getPastTime() {
    var label = lang.t('billing:label.last30days')
    if(this.state.dateRange === '7days') {
      label = lang.t('billing:label.last7days')
    } else if(this.state.dateRange === 'month') {
      label = lang.t('billing:label.thismonth')
    } else if(this.state.dateRange === 'week') {
      label = lang.t('billing:label.thisweek')
    } else if(this.state.dateRange === 'all') {
      label = ''
    }
    return label
  }


  renderPaymentAmount(item) {
    if(item.data.refund !== undefined) {
      return <span>{global.curSym+(item.data.amount/100).toFixed(2)} <span className="pill yellow">{lang.t('billing:label.refunded')}</span></span>
    } else {
      return <span>{global.curSym+(item.data.amount/100).toFixed(2)}</span>
    }
  }


  renderPayments() {
    if(this.state.show === 'payments') {
      if(this.state.paymentsList.length === 0) {
        if(this.state.search === '') {
          return (
            <ListEmpty id='nopayments' var={this.getPastTime()} type='simple'/>
          )
        } else {
          return (
            <ListEmptySearch id='payments'/>
          )
        }
        
      } else {
        var list = this.state.paymentsList
        list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
        list.sort((a,b) => Moment(b.data.timestamp, 'X') - Moment(a.data.timestamp, 'X'));
        return (
          <div>
            {list.map(item => (
              <ListAvatar 
                key={item.id}
                client={this.getClient(item.data.client)}
                clickElement={() => this.showModalPayment(item)}
                columns={[{text: this.getClientName(item.data.client), width: 3, w1280: 6}, {text: this.renderPaymentName(item), width: 3, w1280: 0}, {text: this.renderPaymentAmount(item), width: 2}, {text: this.renderPaymentDate(item), width: 2}]}
              />
            ))}
            <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.PAYMENT)}>{lang.t('billing:button.processanotherpayment')}</button>
          </div>
        )
      }
    }
  }


  renderFilterSelect() {
    if(this.state.groups.length > 0 && HelperBusiness.getRoleIncl('trainer,admin')) {
      var list = this.state.groups
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <select className="light rgt" onChange={event => this.setGroup(event)} value={this.state.currentGroup}>
          <option value='' key='none'>{lang.t('billing:label.allclientgroups')}</option>
          {list.map(item => (
            <option value={item.id} key={item.id}>{item.data.name}</option>
          ))}
        </select>
      )
    }
  }


  renderButtonUpdate() {
    if(this.state.stripe !== '' && this.state.stripe !== undefined) {
      return (
        <button className="btn tertiary small width-6 mt-20" onClick={() => this.getStripePayments()}>{lang.t('billing:button.latestpayments')}</button>
      )
    }
  }


  renderChargeMismatch() {
    if(global.userChargeSessions && (global.userStripeConnect === undefined || global.userStripeConnect === '')) {
      return (
        <div className="infobox large primary mb-30 mt-10">
          <p>{lang.t('billing:label.tracking2')} <Link to={ROUTES.ADMINSETTINGS} className="btn tertiary small rgt">{lang.t('common:button.manage')}</Link></p>
        </div>
      )
    } else if(!global.userChargeSessions && global.userStripeConnect !== undefined && global.userStripeConnect !== '') {
      return (
        <div className="infobox large primary mb-30 mt-10">
          <p>{lang.t('billing:label.tracking1')} <Link to={ROUTES.ADMINSETTINGS} className="btn tertiary small rgt">{lang.t('common:button.manage')}</Link></p>
        </div>
      )
    }
  }


  renderActions() {
    var item1 = {id: 'newpayment', link: ROUTES.PAYMENT}
    var item2 = {id: 'newproduct', link: '/billing/products/new'}
    if(this.state.products.length === 0) {
      item1 = {id: 'newpayment', link: ''}
    }
    if(HelperBusiness.getRoleIncl('')) {
      //no change
    } else if(HelperBusiness.getRole('admin')) {
      item2 = {id: '', link: ''}
    } else if(HelperBusiness.getRole('trainer')) {
      item2 = {id: '', link: ''}
    } else {
      item1 = {id: 'report', link: ROUTES.REPORT}
      item2 = {id: '', link: ''}
    }
    return (
      <WidgetActions
        item1={item1} clickItem1={() => this.setState({showModalLocked: true})}
        item2={item2}
        item3={{id: '', link: ''}}
      />
    )
  }

  renderAvailable() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <div className="lft mt-5">
          <div className={this.state.show === 'payments' ? 'tab active' : 'tab'} onClick={() => this.setState({show: 'payments'})}>
            <p>{lang.t('header:title.payments')}</p>
            <div className="bg"></div>
          </div>
          <div className={this.state.show === 'sessions' ? 'tab active' : 'tab'} onClick={() => this.setState({show: 'sessions'})}>
            <p>{lang.t('header:title.availablesessions')}</p>
            <div className="bg"></div>
          </div>
        </div>
        
      )
    } else {
      return (
        <div className="mt-5 lft">
          <ListHeader id='payments' number={this.state.paymentsList}/>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRole('assistant,assistant2')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='billing-locked' type='simple'/>
          </div>
          <Header type='simple' title={lang.t('nav:main.billing')}/>
          <Navigation active='billing' />
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            {this.renderChargeMismatch()}
            <div className="col-9">
              <WidgetOverview
                item1={{id: 'income', value: (HelperBusiness.getRoleIncl('admin,finance') ? HelperBilling.getIncome(this.state.payments, '', this.state.dateRange) : 'N/A'), prev: (HelperBusiness.getRoleIncl('admin,finance') ? HelperBilling.getIncomeDifference('', this.state.dateRange) : '')}}
                item2={{id: '', value: ''}}
                item3={{id: 'payments', value: this.state.payments.length, prev: HelperBilling.getPaymentsDifference(this.state.dateRange)}}
                item4={{id: 'payclients', value: this.getClientsNum(), prev: this.getClientsDiff()}}
              />
            </div>
            {this.renderActions()}
            <div className="col-12 clear">
              <div className="listheader">
                {this.renderAvailable()}
                {this.renderFilterSelect()}
                <input type="text" className="search light large rgt" placeholder={lang.t('billing:label.filterbyclientname')} value={this.state.search} onChange={(val) => this.filterPayments(val)} />
                <div className="clear"></div>
              </div>
              {this.renderSessions()}
              {this.renderPayments()}
              <div className="sv clear"></div>
              {this.renderButtonUpdate()}
              <div className="sv-20 clear"></div>
            </div>
            <div className="sv-40 clear"></div>
          </div>
          <Header type='billing' active='payments' selector={true} updateSelect={() => this.setDateRange()} onClickInfo={() => this.setState({showModalInfo: true})}/>
          <Navigation active='billing'/>
          <ModalBilling type='info' show={this.state.showModalInfo} onHide={() => this.hideModals()}/>
          <ModalBilling type='locked' show={this.state.showModalLocked} onHide={() => this.hideModals()}/>
          <ModalPayment payment={this.state.currentPayment} show={this.state.showModalPayment} onRefund={() => this.confirmRefundPayment()} onHide={() => this.hideModals()}/>
          <ModalMessageSimple type='refund' show={this.state.showModalConfirm} onHide={() => this.hideModals()} clickMainButton={() => this.refundPayment()}/>
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['billing','common','header','nav','messaging'])(withRouter(withAuthorization(condition)(BillingPage)));