import Moment from 'moment';
import HelperBusiness from '../helper/Business';
import lang from 'i18next';



class HelperActivity {


  
  getActivityAll(type) {
    var array = []

    // Payments & Billing
    for(var itemb of global.payments) {
      if(type.indexOf('billing') !== -1 && HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
        var date = Moment(itemb.data.date, 'DD/MM/YYYY HH:mm')
        var desc = itemb.data.name
        var method = itemb.data.type+' ending '+itemb.data.last4
        var icon = 'card'
        if(itemb.data.name.indexOf('Subscription') !== -1 || itemb.data.name.indexOf('Invoice') !== -1) {
          desc = lang.t('home:actclient.membership')
        }
        if(itemb.data.type === 'Cash') {
          method = lang.t('home:act.otherpaymentmethod')
          icon = 'dollar'
        }
        if(itemb.data.timestamp !== undefined) {
          date = Moment(itemb.data.timestamp, 'X')
        }
        if(Moment(date) > Moment().add(-14, 'days')) {
          if(this.checkClientExists(itemb.data.client)) {
            array.push({
              id: itemb.id,
              date: date,
              color: 'secondary',
              icon: icon,
              title: lang.t('home:act.wascharged')+global.curSym+(itemb.data.amount/100).toFixed(2),
              desc: desc+' - '+method,
              activity: 'payment',
              client: itemb.data.client
            })
          }
        }
      }
    }

    // Invoices
    for(var itemi of global.invoices) {
      if(type.indexOf('billing') !== -1 && HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
        var datei = Moment(itemi.data.date, 'X')
        if(Moment(datei) > Moment().add(-14, 'days')) {
          if(this.checkClientExists(itemi.data.client)) {
            array.push({
              id: itemi.id,
              date: datei,
              color: 'secondary',
              icon: 'invoice',
              title: lang.t('home:act.wassentinvoice'),
              desc: global.curSym+itemi.data.price.toFixed(2)+' - '+lang.t('home:act.due')+' '+Moment(itemi.data.due, 'X').locale(lang.language).format('D MMM YYYY'),
              activity: 'invoice',
              client: itemi.data.client
            })
          }
        }
      }
    }

    // Activity
    var arrs = []
    var activity = global.activity.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
    for(var itema of activity) {
      var datea = Moment(itema.data.date, 'DD/MM/YYYY HH:mm')

      if(itema.data.type === 'cashrefunded' && type.indexOf('billing') !== -1 && HelperBusiness.getRoleIncl('')) {
        var arract = itema.data.data.split(',')
        array.push({
          id: itema.id,
          date: datea,
          color: 'secondary',
          icon: 'dollar',
          title: lang.t('home:act.refunded'),
          desc: global.curSym+(arract[1]/100).toFixed(2),
          activity: 'cashrefund',
          client: arract[0]
        })
      }

      if(itema.data.type === 'paymentrefunded' && type.indexOf('billing') !== -1 && HelperBusiness.getRoleIncl('')) {
        var arract = itema.data.data.split(',')
        array.push({
          id: itema.id,
          date: datea,
          color: 'secondary',
          icon: 'card',
          title: lang.t('home:act.paymentrefunded'),
          desc: global.curSym+(arract[1]/100).toFixed(2),
          activity: 'refund',
          client: arract[0]
        })
      }

      if(itema.data.type === 'debiterror' && type.indexOf('billing') !== -1 && HelperBusiness.getRoleIncl('')) {
        array.push({
          id: itema.id,
          date: Moment(itema.data.timestamp, 'X'),
          color: 'yellow',
          icon: 'warning',
          title: lang.t('home:act.membershipsetupfailed'),
          desc: itema.data.data,
          activity: 'clientbilling',
          client: itema.data.client
        })
      }

      if(itema.data.type === 'chargeerror' && type.indexOf('billing') !== -1 && HelperBusiness.getRoleIncl('')) {
        array.push({
          id: itema.id,
          date: Moment(itema.data.timestamp, 'X'),
          color: 'yellow',
          icon: 'warning',
          title: lang.t('home:act.paymentfailed'),
          desc: itema.data.data,
          activity: 'clientbilling',
          client: itema.data.client
        })
      }

      if(itema.data.type === 'subscription' && type.indexOf('billing') !== -1 && HelperBusiness.getRoleIncl('')) {
        array.push({
          id: itema.id,
          date: Moment(itema.data.timestamp, 'X'),
          color: 'yellow',
          icon: 'warning',
          title: lang.t('home:act.membershipissue'),
          desc: lang.t('home:act.membershipissue.text'),
          activity: 'clientbilling',
          client: itema.data.client
        })
      }

      if(itema.data.type === 'booking' && type.indexOf('sessions') !== -1) {
        var arracts = itema.data.data.split(',')
        if(arrs.indexOf(arracts[1]) === -1) { 
          var id1 = ''
          var descb = lang.t('home:act.groupsession')
          //var dateb = Moment()
          for(var cl1 of global.clients) {
            if(cl1.data.uid === arracts[0]) {
              id1 = cl1.id
            }
          }
          for(var items of global.sessions) {
            if(items.id === arracts[1]) {
              var name = lang.t('home:act.groupsession')+' - '
              //dateb = Moment(items.data.date, 'DD/MM/YYYY HH:mm')
              if(items.data.client !== '') {
                name = items.data.client+' - '
              }
              if(items.data.availability !== undefined) {
                name = lang.t('home:act.available11session')+' - '
              }
              descb = name+this.getDate(items.data.date)
            }
          }
          if(this.checkClientExists(id1)) {
            arrs.push(arracts[1])
            array.push({
              id: arracts[1],
              date: datea,
              color: 'primary',
              icon: 'group',
              title: lang.t('home:act.bookedsession'),
              desc: descb,
              activity: 'group',
              client: id1
            })
          }
        }
      }

      if(itema.data.type === 'bookingcancelled' && type.indexOf('sessions') !== -1) {
        var arractc = itema.data.data.split(',')
        var descc = lang.t('home:act.groupsession')
        var idc = ''
        for(var cl1c of global.clients) {
          if(cl1c.data.uid === arractc[0]) {
            idc = cl1c.id
          }
        }
        for(var itemc of global.sessions) {
          if(itemc.id === arractc[1]) {
            var namec = lang.t('home:act.groupsession')+' - '
            if(itemc.data.client !== '') {
              namec = itemc.data.client+' - '
            }
            if(itemc.data.availability !== undefined) {
              namec = lang.t('home:act.available11session')+' - '
            }
            descc = namec+this.getDate(itemc.data.date)
          }
        }
        array.push({
          id: arractc[1],
          date: datea,
          color: 'primary',
          icon: 'group',
          title: lang.t('home:act.canceledsessionbooking'),
          desc: descc,
          activity: 'group',
          client: idc
        })
      }

      if(itema.data.type === 'bookingevent' && type.indexOf('sessions') !== -1) {
        var arracte = itema.data.data.split(',')
        if(arrs.indexOf(arracte[1]) === -1) { 
          var id1e = ''
          var descb1 = lang.t('home:act.groupsession')
          //var dateb = Moment()
          for(var cl11 of global.clients) {
            if(cl11.data.uid === arracte[0]) {
              id1e = cl11.id
            }
          }
          for(var iteme of global.events) {
            if(iteme.id === arracte[1]) {
              descb1 = iteme.data.title+' - '+this.getDate(iteme.data.date)
            }
          }
          if(this.checkClientExists(id1e)) {
            arrs.push(arracte[1])
            array.push({
              id: arracte[1],
              date: datea,
              color: 'primary',
              icon: 'event',
              title: lang.t('home:act.bookedevent'),
              desc: descb1,
              activity: 'event',
              client: id1e
            })
          }
        }
      }

      if(itema.data.type === 'bookingeventcancelled' && type.indexOf('sessions') !== -1) {
        var arractce = itema.data.data.split(',')
        var descce = lang.t('home:act.groupsession')
        //var datece = Moment()
        var idce = ''
        for(var cl1ce of global.clients) {
          if(cl1ce.data.uid === arractce[0]) {
            idce = cl1ce.id
          }
        }
        for(var itemce of global.events) {
          if(itemce.id === arractce[1]) {
            descce = itemce.data.title+' - '+this.getDate(itemce.data.date)
          }
        }
        //if(datec > Moment()) {
          array.push({
            id: arractce[1],
            date: datea,
            color: 'primary',
            icon: 'event',
            title: lang.t('home:act.canceledeventbooking'),
            desc: descce,
            activity: 'event',
            client: idce
          })
        //}
      }

      var typeactasst = ['trainerinvited', 'traineraccepted', 'trainerconnected']
      if(typeactasst.indexOf(itema.data.type) !== -1 && type.indexOf('business') !== -1) {
        var iconact = 'invite'
        var titleasst = lang.t('home:act.request1')
        var descasst = lang.t('home:act.request1.text1')+itema.data.data+lang.t('home:act.request1.text2')
        var username = 'Assistant trainer'
        if(itema.data.type === 'traineraccepted') {
          iconact = 'accept'
          titleasst = lang.t('home:act.request2')
          descasst = itema.data.data+lang.t('home:act.request2.text')
        }
        if(itema.data.type === 'trainerconnected') {
          iconact = 'accept'
          titleasst = lang.t('home:act.request3')
          descasst = lang.t('home:act.request3.text')+itema.data.data
        }
        array.push({
          id: itema.id,
          date: datea,
          color: 'quarternary',
          icon: iconact,
          title: titleasst,
          desc: descasst,
          activity: '-',
          client: username
        })
      }

      if(itema.data.type === 'staff' && type.indexOf('business') !== -1 && HelperBusiness.getRoleIncl('')) {
        //var arrst = itema.data.data.split(',')
        var sname = lang.t('home:act.staffmember')
        for(var st of global.userStaff) {
          if(st.id === itema.id) {
            sname = st.data.name
          }
        }
        array.push({
          id: itema.id,
          date: datea,
          color: 'quarternary',
          icon: 'accept',
          title: lang.t('home:act.staffconnected'),
          desc: sname+lang.t('home:act.staffconnected.text'),
          activity: 'staff',
          client: sname
        })
      }

      // Client connection
      var typeactcon = ['invite', 'inviteaccepted', 'request', 'requestaccepted', 'clientconnected', 'form', 'newclient']
      if(typeactcon.indexOf(itema.data.type) !== -1 && type.indexOf('client') !== -1 && HelperBusiness.getRoleIncl('')) {
        var iconactcon = 'request'
        var titlecon = lang.t('home:act.connect1')
        var desccon = lang.t('home:act.connect1.text')
        var client = lang.t('home:act.newclient')
        var activitycon = '-'
        var namecon = lang.t('home:act.client')
        for(var cl2 of global.clients) {
          if(cl2.data.uid === itema.data.data) {
            client = cl2.id
            namecon = cl2.data.name
            activitycon = 'newclient'
          }
        }
        if(itema.data.type === 'requestaccepted') {
          iconactcon = 'accept'
          titlecon = lang.t('home:act.connect2')
          desccon = lang.t('home:act.connect2.text')
        }
        if(itema.data.type === 'invite') {
          iconactcon = 'invite'
          titlecon = lang.t('home:act.invite1')
          desccon = lang.t('home:act.invite1.text')
        }
        if(itema.data.type === 'inviteaccepted') {
          iconactcon = 'accept'
          titlecon = lang.t('home:act.invite2')
          desccon = lang.t('home:act.invite2.text')
        }
        if(itema.data.type === 'clientconnected') {
          iconactcon = 'accept'
          titlecon = namecon+lang.t('home:act.connected')
          desccon = lang.t('home:act.connected.text')
          activitycon = 'note'
        }
        if(itema.data.type === 'form') {
          var arract1 = itema.data.data.split(',')
          client = arract1[0]
          iconactcon = 'form'
          titlecon = lang.t('home:act.form')
          desccon = lang.t('home:act.form.text')
          activitycon = "note"
        }
        if(itema.data.type === 'newclient') {
          iconactcon = 'active'
          titlecon = namecon+lang.t('home:act.connected2')
          desccon = lang.t('home:act.connected2.text')
          activitycon = "note"
        }
        if(this.checkClientExists(client)) {
          array.push({
            id: itema.id,
            date: datea,
            color: 'tertiary',
            icon: iconactcon,
            title: titlecon,
            desc: desccon,
            activity: activitycon,
            client: client
          })
        }
      }
    }

    // Requests
    if(type.indexOf('business') !== -1 && HelperBusiness.getRoleIncl('admin')) {
      if(global.publicRequests !== undefined) {
        for(var itemp of global.publicRequests) {
          if(Moment(itemp.data.sent, 'DD/MM/YYYY HH:mm') > Moment().add(-14, 'days')) {
            array.push({
              id: itemp.id,
              date: Moment(itemp.data.sent, 'DD/MM/YYYY HH:mm'),
              color: 'quarternary',
              icon: 'inbox',
              title: lang.t('home:act.lead'),
              desc: itemp.data.name+lang.t('home:act.lead.text'),
              activity: 'trial',
              client: itemp.data.name
            })
          }
        }
      }  
    }

    // Comments
    if(type.indexOf('sessions') !== -1) {
      for(var items2 of global.sessions) {
        var name2 = lang.t('home:actclient.11session')
        var act = 'comment'
        if(items2.data.group) {
          name2 = lang.t('home:act.groupsession')
          act = 'commentgroup'
          if(items2.data.client !== '' && items2.data.client !== undefined) {
            name2 = items2.data.client
          }
          if(items2.data.availability !== undefined) {
            name2 = lang.t('home:act.available11session')
            act = 'commentavail'
          }
        }
        for(var comm of items2.comments) {
          if(comm.data.sender !== global.uidUser && Moment(comm.data.date, 'X') > Moment().add(-14, 'days')) {
            if(this.checkClientExists(comm.data.sender)) {
              array.push({
                id: items2.id,
                date: Moment(comm.data.date, 'X'),
                color: 'primary',
                icon: 'comment',
                title: lang.t('home:act.commentedsession'),
                desc: name2+' '+this.getDate(items2.data.date),
                activity: act,
                client: comm.data.sender
              })
            }
          }
        }
      }
      for(var items1 of global.sessionsTraining) {
        for(var comm1 of items1.comments) {
          if(comm1.data.sender !== global.uidUser && Moment(comm1.data.date, 'X') > Moment().add(-14, 'days')) {
            array.push({
              id: items1.id,
              date: Moment(comm1.data.date, 'X'),
              color: 'primary',
              icon: 'comment',
              title: lang.t('home:act.commentedtrainingsession'),
              desc: 'Session '+this.getDate(items1.data.date),
              activity: 'comment',
              client: comm1.data.sender
            })
          }
        }
      }
    }

    if(type.indexOf('billing') !== -1 && HelperBusiness.getRoleIncl('admin,finance,cs')) {
      for(var client1 of global.clients) {
        for(var hist of client1.history) {
          if(hist.data.title === 'Membership cancelled' && Moment(hist.data.date, 'DD/MM/YYYY HH:mm') > Moment().add(-14, 'days')) {
            array.push({
              id: hist.id,
              date: Moment(hist.data.date, 'DD/MM/YYYY HH:mm'),
              color: 'secondary',
              icon: 'refresh',
              title: "'s "+hist.data.title,
              desc: hist.data.desc,
              activity: 'newclient',
              client: client1.id
            })
          }
        }
        for(var cred of client1.creditse) {
          if(Moment(cred.data.expires, 'X') < Moment() && Moment(cred.data.expires, 'X') > Moment().add(-14, 'days')) {
            array.push({
              id: cred.id,
              date: Moment(cred.data.expires, 'X'),
              color: 'red',
              icon: 'card',
              title: lang.t('home:act.creditsexpired'),
              desc: cred.data.sessionsPaid+' '+(cred.data.group ? lang.t('home:act.creditsexpired.group') : lang.t('home:act.creditsexpired.11')),
              activity: 'clientbilling',
              client: client1.id
            })
          }
        }
        for(var sub of client1.subscriptions) {
          if(Moment(sub.data.next, 'X') < Moment() && Moment(sub.data.next, 'X') > Moment().add(-14, 'days')) {
            array.push({
              id: sub.id,
              date: Moment(sub.data.next, 'X'),
              color: 'yellow',
              icon: 'warning',
              title: lang.t('home:act.trial'),
              desc: lang.t('home:act.trial.text'),
              activity: 'clientbilling',
              client: client1.id
            })
          }
        }
      }
    }

    return array
  }





  getActivityClient(client, type) {
    var array = []

    // Client stuff
    if(type.indexOf('client') !== -1 && HelperBusiness.getRoleIncl('trainer,admin')) {
      for(var itema of client.assessments) {
        if(Moment(itema.data.date, 'DD/MM/YYYY HH:mm') > Moment().add(-14, 'days')) {
          array.push({
            id: itema.id,
            date: Moment(itema.data.date, 'DD/MM/YYYY HH:mm'),
            color: 'tertiary',
            icon: 'assessment',
            title: lang.t('home:actclient.healthlog'),
            desc: lang.t('home:actclient.healthlog.text'),
            activity: 'assessment'
          })
        } 
      }
      for(var itemn of client.history) {
        if(Moment(itemn.data.date, 'DD/MM/YYYY HH:mm') > Moment().add(-14, 'days') && (itemn.data.title === 'Inactive' || itemn.data.title === 'Active')) {
          array.push({
            id: itemn.id,
            date: Moment(itemn.data.date, 'DD/MM/YYYY HH:mm'),
            color: 'tertiary',
            icon: 'accept',
            title: itemn.data.title,
            desc: lang.t('home:actclient.active')+itemn.data.title.toLowerCase(),
            activity: 'note'
          })
        }
      }
      for(var sub of client.subscriptions) {
        if(Moment(sub.data.next, 'X') < Moment() && Moment(sub.data.next, 'X') > Moment().add(-14, 'days')) {
          array.push({
            id: sub.id,
            date: Moment(sub.data.next, 'X'),
            color: 'yellow',
            icon: 'warning',
            title: lang.t('home:actclient.trial'),
            desc: lang.t('home:actclient.trial.text'),
            activity: 'clientbilling',
          })
        }
      }
    }

    // Payments
    if(type.indexOf('billing') !== -1 && HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      for(var item1 of global.payments) {
        var date = Moment(item1.data.date, 'DD/MM/YYYY HH:mm')
        var desc1 = item1.data.name
        var method = item1.data.type+lang.t('home:actclient.ending')+item1.data.last4
        var icon = 'card'
        if(item1.data.name.indexOf('Subscription') !== -1 || item1.data.name.indexOf('Invoice') !== -1) {
          desc1 = lang.t('home:actclient.membership')
        }
        if(item1.data.type === 'Cash') {
          method = lang.t('home:act.otherpaymentmethod')
          icon = 'dollar'
        }
        if(item1.data.timestamp !== undefined) {
          date = Moment(item1.data.timestamp, 'X')
        }
        if(item1.data.client === client.id && Moment(date) > Moment().add(-14, 'days')) {
          array.push({
            id: item1.id,
            date: date,
            color: 'secondary',
            icon: icon,
            title: global.curSym+(item1.data.amount/100).toFixed(2)+lang.t('home:actclient.charged'),
            desc: desc1+' - '+method,
            activity: 'payment',
          })
        }
      }

      // Invoices
      for(var itemi of global.invoices) {
        var datei = Moment(itemi.data.date, 'X')
        if(itemi.data.client === client.id && Moment(datei) > Moment().add(-14, 'days')) {
          array.push({
            id: itemi.id,
            date: datei,
            color: 'secondary',
            icon: 'invoice',
            title: lang.t('home:actclient.invoicesent'),
            desc: global.curSym+itemi.data.price.toFixed(2)+' - '+lang.t('home:act.due')+' '+Moment(itemi.data.due, 'X').locale(lang.language).format('D MMM YYYY'),
            activity: 'invoice',
          })
        }
      }
    }

    if(type.indexOf('billing') !== -1 && HelperBusiness.getRoleIncl('trainer')) {
      for(var cl of global.clients) {
        if(cl.id === client.id) {
          for(var hist of cl.history) {
            if(hist.data.title === 'Membership cancelled' && Moment(hist.data.date, 'DD/MM/YYYY HH:mm') > Moment().add(-14, 'days')) {
              array.push({
                id: hist.id,
                date: Moment(hist.data.date, 'DD/MM/YYYY HH:mm'),
                color: 'secondary',
                icon: 'card',
                title: hist.data.title,
                desc: hist.data.desc,
                activity: 'newclient',
                client: cl.id
              })
            }
          }
          for(var cred of cl.creditse) {
            if(Moment(cred.data.expires, 'X') < Moment() && Moment(cred.data.expires, 'X') > Moment().add(-14, 'days')) {
              array.push({
                id: cred.id,
                date: Moment(cred.data.expires, 'X'),
                color: 'red',
                icon: 'card',
                title: lang.t('home:actclient.creditsexpired'),
                desc: cred.data.sessionsPaid+' '+(cred.data.group ? lang.t('home:actclient.creditsexpired.group') : lang.t('home:actclient.creditsexpired.11')),
                activity: 'clientbilling',
                client: cl.id
              })
            }
          }
        }
      }
    }

    // Training sessions
    if(type.indexOf('sessions') !== -1) {
      for(var items of global.sessionsTraining) {
        var dates = Moment(items.data.date, 'DD/MM/YYYY HH:mm')
        if(items.data.uid === client.data.uid && dates > Moment().add(-14, 'days') && items.data.attendance === 3) {
          array.push({
            id: items.id,
            date: dates,
            color: 'primary',
            icon: 'training',
            title: lang.t('home:actclient.trainingsession'),
            desc: lang.t('home:actclient.trainingsession.text'),
            activity: 'training'
          })
        }
        for(var comm of items.comments) {
          if(comm.data.sender !== global.uidUser && Moment(comm.data.date, 'X') > Moment().add(-14, 'days')) {
            if(this.checkClientExists(comm.data.sender) && items.data.uid === client.data.uid) {
              array.push({
                id: items.id,
                date: Moment(comm.data.date, 'X'),
                color: 'primary',
                icon: 'comment',
                title: lang.t('home:actclient.commentedtraining'),
                desc: 'Session '+this.getDate(items.data.date),
                activity: 'comment',
              })
            }
          }
        }
      }

      for(var items1 of global.sessions) {
        var adds = false
        if(global.loc === '') {
          adds = true
        } else {
          if(items1.data.location === global.loc) {
            adds = true
          }
        }
        var name1 = lang.t('home:actclient.11session')
        var act = 'comment'
        if(items1.data.group) {
          name1 = lang.t('home:act.groupsession')
          act = 'commentgroup'
          if(items1.data.client !== '' && items1.data.client !== undefined) {
            name1 = items1.data.client
          }
          if(items1.data.availability !== undefined) {
            name1 = lang.t('home:act.available11session')
            act = 'commentavail'
          }
        }
        for(var comm1 of items1.comments) {
          if(comm1.data.sender !== global.uidUser &&  Moment(comm1.data.date, 'X') > Moment().add(-14, 'days') && comm1.data.sender === client.id) {
            if(this.checkClientExists(comm1.data.sender) && adds) {
              array.push({
                id: items1.id,
                date: Moment(comm1.data.date, 'X'),
                color: 'primary',
                icon: 'comment',
                title: lang.t('home:actclient.commentedsession'),
                desc: name1+' '+this.getDate(items1.data.date),
                activity: act,
              })
            }
          }
        }
      }
    }

    // Activity list
    var typeact = ['waiting', 'waitingcancelled', 'booking', 'bookingcancelled', 'plan', 'bookingevent', 'bookingeventcancelled']
    var typeact2 = ['invite', 'inviteaccepted', 'request', 'requestaccepted', 'form']
    for(var itemact of global.activity) {
      var dateact = Moment(itemact.data.date, 'DD/MM/YYYY HH:mm')
      var title = lang.t('home:actclient.bookedsession')
      var desc = lang.t('home:act.groupsession')
      var iconact = 'group'
      var activity = 'group'

      // Sessions
      if(typeact.indexOf(itemact.data.type) !== -1 && type.indexOf('sessions') !== -1 && dateact > Moment().add(-14, 'days')) {
        var arract = itemact.data.data.split(',')
        if(arract[0] === client.data.uid) {
          
          if(arract.length > 1) {
            var found = false
            for(var sess of global.sessions) {
              if(sess.id === arract[1]) {
                var name = lang.t('home:act.groupsession')+' - '
                found = true
                if(sess.data.client !== '') {
                  name = sess.data.client+' - '
                }
                if(sess.data.availability !== undefined) {
                  name = lang.t('home:act.available11session')+' - '
                  activity = 'availability'
                }
                desc = name+this.getDate(sess.data.date)
              }
            }
            for(var ev of global.events) {
              if(ev.id === arract[1]) {
                found = true
                activity = 'event'
                desc = ev.data.title+' - '+this.getDate(ev.data.date)
              }
            }
            if(itemact.data.type === 'bookingcancelled') {
              title = lang.t('home:actclient.canceledsession')
            }
            if(itemact.data.type === 'waiting') {
              title = lang.t('home:actclient.waitingsession')
              iconact = 'waiting'
            }
            if(itemact.data.type === 'waitingcancelled') {
              title = lang.t('home:actclient.leftwaiting')
              iconact = 'waiting'
            }
            if(itemact.data.type === 'bookingevent') {
              title = lang.t('home:act.bookedevent')
            }
            if(itemact.data.type === 'bookingeventcancelled') {
              title = lang.t('home:actclient.canceledsession')
            }
            if(itemact.data.type === 'plan') {
              title = lang.t('home:actclient.startedtrainingplan')
              iconact = 'plan'
              desc = lang.t('home:actclient.trainingplan')
              activity = 'plan'
              for(var plan of global.plans) {
                if(plan.id === arract[1]) {
                  desc = plan.data.name
                }
              }
              iconact = 'plan'
            }
            if(found) {
              array.push({
                id: arract[1],
                date: dateact,
                color: 'primary',
                icon: iconact,
                title: title,
                desc: desc,
                activity: activity
              })
            }
            
          }
        }
      }

      // Client connection
      if(typeact2.indexOf(itemact.data.type) !== -1 && type.indexOf('client') !== -1 && dateact > Moment().add(-14, 'days') && HelperBusiness.getRoleIncl('')) {
        var arract2 = itemact.data.data.split(',')
        if(client.data.email === itemact.data.data || client.data.uid === itemact.data.data || client.id === arract2[0]) {
          iconact = 'request'
          title = lang.t('home:actclient.request')
          desc = lang.t('home:actclient.request.text')
          var activity1 = '-'
          if(itemact.data.type === 'requestaccepted') {
            iconact = 'accept'
            title = lang.t('home:actclient.connected')
            desc = lang.t('home:actclient.connected.text')
          }
          if(itemact.data.type === 'invite') {
            iconact = 'invite'
            title = lang.t('home:actclient.invite')
            desc = lang.t('home:actclient.invite.text')
          }
          if(itemact.data.type === 'inviteaccepted') {
            iconact = 'accept'
            title = lang.t('home:actclient.invite2')
            desc = lang.t('home:actclient.invite2.text')
          }
          if(itemact.data.type === 'form') {
            iconact = 'form'
            title = lang.t('home:actclient.form')
            desc = lang.t('home:actclient.form.text')
            activity1 = "note"
          }
          array.push({
            id: itemact.id,
            date: dateact,
            color: 'tertiary',
            icon: iconact,
            title: title,
            desc: desc,
            activity: activity1
          })
        }
      }

      if(itemact.data.type === 'cashrefunded' && type.indexOf('billing') !== -1 && dateact > Moment().add(-14, 'days') && HelperBusiness.getRoleIncl('trainer')) {
        var arract3 = itemact.data.data.split(',')
        if(arract3[0] === client.id) {
          array.push({
            id: itemact.id,
            date: dateact,
            color: 'secondary',
            icon: 'dollar',
            title: lang.t('home:actclient.cashrefunded'),
            desc: global.curSym+(arract3[1]/100).toFixed(2),
            activity: '-'
          })
        }
      }

      if(itemact.data.type === 'paymentrefunded' && type.indexOf('billing') !== -1 && dateact > Moment().add(-14, 'days') && HelperBusiness.getRoleIncl('trainer')) {
        var arract3 = itemact.data.data.split(',')
        if(arract3[0] === client.id) {
          array.push({
            id: itemact.id,
            date: dateact,
            color: 'secondary',
            icon: 'card',
            title: lang.t('home:actclient.paymentrefunded'),
            desc: global.curSym+(arract3[1]/100).toFixed(2),
            activity: '-'
          })
        }
      }

      if(itemact.data.type === 'debiterror' && itemact.data.client === client.id && type.indexOf('billing') !== -1 && dateact > Moment().add(-14, 'days') && HelperBusiness.getRoleIncl('')) {
        array.push({
          id: itemact.id,
          date: Moment(itemact.data.timestamp, 'X'),
          color: 'yellow',
          icon: 'warning',
          title: lang.t('home:actclient.membershipfailed'),
          desc: itemact.data.data,
          activity: 'clientbilling',
        })
      }

      if(itemact.data.type === 'chargeerror' && itemact.data.client === client.id && type.indexOf('billing') !== -1 && dateact > Moment().add(-14, 'days') && HelperBusiness.getRoleIncl('trainer')) {
        array.push({
          id: itemact.id,
          date: Moment(itemact.data.timestamp, 'X'),
          color: 'yellow',
          icon: 'warning',
          title: lang.t('home:actclient.paymentfailed'),
          desc: itemact.data.data,
          activity: 'clientbilling',
        })
      }

      if(itemact.data.type === 'subscription' && itemact.data.client === client.id && type.indexOf('billing') !== -1 && dateact > Moment().add(-14, 'days') && HelperBusiness.getRoleIncl('trainer')) {
        array.push({
          id: itemact.id,
          date: Moment(itemact.data.timestamp, 'X'),
          color: 'yellow',
          icon: 'warning',
          title: lang.t('home:actclient.membershipissue'),
          desc: lang.t('home:actclient.membershipissue.text'),
          activity: 'clientbilling',
        })
      }
    }

    return array
  }




  getDate(date) {
    var label = Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('llll')
    var d1 = Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
    if(d1 === Moment().format('DD/MM/YYYY')) {
      label = lang.t('home:label.today')+' '+Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    if(d1 === Moment().add(1, 'days').format('DD/MM/YYYY')) {
      label = lang.t('home:label.tomorrow')+' '+Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    if(d1 === Moment().add(-1, 'days').format('DD/MM/YYYY')) {
      label = lang.t('home:label.yesterday')+' '+Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    return label
  }


  checkClientExists(id) {
    var exists = false
    for(var item of global.clients) {
      if(item.id === id) {
        exists = true
      }
    }
    for(var item1 of global.clientsInactive) {
      if(item1.id === id) {
        exists = true
      }
    }
    return exists
  }


}





const b = new HelperActivity();
export default b;